import { TextInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { SectionTitle } from '../../../../../components/text';
import { TariffData } from '../../../../../types/tariffTypes';
import { moneyFormatter } from '../../../../../utils/currency';

interface ITariffQuotaDisplayDataProps {
  tariffData: TariffData;
  paydayDescription?: string;
  showTuitionAmount?: boolean;
  tuitionFree?: boolean;
  children: JSX.Element;
}
export default function TariffDisplayData({
  tariffData,
  paydayDescription,
  showTuitionAmount = false,
  tuitionFree = false,
  children,
}: ITariffQuotaDisplayDataProps) {
  const prefix = 'tuitionProcessNoSua.tariffQuotas';
  const { t } = useTranslation();

  const [tariff, tuition] = useMemo(
    () => [
      moneyFormatter().format(tariffData.studyPlan.tariff.value),
      moneyFormatter().format(
        !tuitionFree
          ? tariffData.studyPlan.admissions?.[0].postulationsDetail?.[0]
              .postulation?.period?.tuitionPeriod?.value ?? 0
          : 0,
      ),
    ],
    [
      tariffData.studyPlan.admissions,
      tariffData.studyPlan.tariff.value,
      tuitionFree,
    ],
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.displayData.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="studyPlan"
            label={t(`${prefix}.displayData.studyPlan`)}
            disabled
            value={
              tariffData.studyPlan.studyPlanVersionOffer.studyPlanVersion?.name
            }
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="campus"
            label={t(`${prefix}.displayData.campus`)}
            disabled
            value={tariffData.studyPlan.studyPlanVersionOffer.campus?.name}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="schedule"
            label={t(`${prefix}.displayData.schedule`)}
            disabled
            value={tariffData.studyPlan.studyPlanVersionOffer.schedule?.name}
          />
        </Col>
        {showTuitionAmount ? (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="tuitionAmount"
              label={t(`${prefix}.displayData.tuition`)}
              disabled
              value={tuition}
            />
          </Col>
        ) : null}
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="tariff"
            label={t(
              `${prefix}.displayData.${
                tariffData.studyPlan.tariff.hasDiscount
                  ? 'discountedTariff'
                  : 'tariff'
              }`,
            )}
            disabled
            value={tariff}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="payDay"
            label={t(`${prefix}.displayData.payDay`)}
            disabled
            value={paydayDescription}
          />
        </Col>
        {children}
      </Row>
    </>
  );
}
