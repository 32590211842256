import { Button } from "@octano/global-ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import {
  getDocumentPreview,
  getTermsAndConditions,
  tokenPreviewDocument,
} from "../../../../api/requests/tuitionProcess";
import DisplayError from "../../../../components/info/DisplayError";
import Loading from "../../../../components/info/Loading";
import { SectionTitle } from "../../../../components/text";
import { useLoadingState } from "../../../../hooks/useLoadingState";
import { useStepState } from "../../../../hooks/useStepState";
import { useUserState } from "../../../../hooks/useUserState";
import DocumentItem from "./DocumentItem";
import TermsAndConditionsForm from "./TermsAndConditionsForm";

/**
 * Paso "Términos y condiciones" del proceso de matrícula
 */
const TermsAndConditions = () => {
  const prefix = "tuitionProcess.termsAndConditions";
  const { t } = useTranslation();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { fullName, setIsSessionExpired } = useUserState();
  const { prevStep } = useStepState();
  const [hasGratuity, setHasGratuity] = useState(false);

  const getTermsAndConditionsStepInfo = useCallback(async () => {
    const { data, error } = await getTermsAndConditions();
    if (data) {
      setHasGratuity(data.hasGratuity);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [setErrorLoading, setLoading, setIsSessionExpired]);

  useEffect(() => {
    if (loading) {
      getTermsAndConditionsStepInfo();
    }
  }, [loading, getTermsAndConditionsStepInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // TODO: Descargar los otros documentos
  const getPDF = useCallback(async () => {
    const { data, error } = await tokenPreviewDocument();
    if (data && !error) {
      const url = getDocumentPreview(data);
      window.open(url);
    } else if (error) {
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      }
      // TODO: error al obtener el token para el preview del documento
    }
  }, [setIsSessionExpired]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12}>
          <Button
            icon="back"
            onClick={() => prevStep()}
            outlined
            rounded
            size="sm"
            text={t(`common.btn.goBackStep`)}
          />
        </Col>
        <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
          <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
          <br />
          <span className="fs-18 text-primary fw-600">{fullName}</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <DocumentItem
          name={t(`${prefix}.contract`)}
          nameIcon="contract"
          btnText={t(`${prefix}.btnContract`)}
          actionBtn={() => 1}
        />
        <DocumentItem
          name={t(`${prefix}.promissoryNote`)}
          nameIcon="promissory_note"
          btnText={t(`${prefix}.btnPromissoryNote`)}
          actionBtn={() => getPDF()}
        />
        <DocumentItem
          name={t(`${prefix}.insurance`)}
          nameIcon="sustaining_contract"
          btnText={t(`${prefix}.btnInsurance`)}
          actionBtn={() => 1}
        />
      </Row>
      <TermsAndConditionsForm hasGratuity={hasGratuity} />
    </Card>
  );
};

export default TermsAndConditions;
