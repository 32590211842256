import { Alert, Button, SelectOptionType } from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import LoginFormFields, { LoginInputs } from './LoginFormFields';

import { useLocation } from 'react-router-dom';
import { ReactComponent as AddemicProductLogo } from '../../assets/addemic/logo_expanded.svg';
import { IS_ADDEMIC } from '../../config/constants';

interface LoginFormProps {
  errorLogin: string;
  toggleError: () => void;
  onLogin: (values: LoginInputs) => void;
}

const LoginForm = ({ errorLogin, toggleError, onLogin }: LoginFormProps) => {
  const prefix = 'loginTuitionProcess';
  const { t } = useTranslation();
  const { state: defaultValues } = useLocation<{
    rut?: string;
    passportNumber?: string;
    passportCountry?: SelectOptionType;
    hasPassport?: boolean;
  }>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<LoginInputs>({ mode: 'onChange', defaultValues });

  const hasPassport = watch('hasPassport');

  return (
    <Row className="py-5">
      <Col xs={12} className="text-center">
        {IS_ADDEMIC ? (
          <AddemicProductLogo style={{ maxHeight: 80 }} />
        ) : (
          <img
            src="/tenant/logo_expanded.svg"
            alt="tenant_logo"
            style={{ maxHeight: 100 }}
          />
        )}
      </Col>
      <Col xs={12}>
        <p className="text-center py-4">
          <Trans t={t}>{t(`${prefix}.welcome`)}</Trans>
        </p>
      </Col>

      <Col xs={12}>
        <Alert
          color="danger"
          icon="error"
          noFill
          size="sm"
          text={errorLogin !== '' ? t(`${prefix}.${errorLogin}`) : ''}
          isOpen={errorLogin !== ''}
          toggle={toggleError}
        />
      </Col>

      <Col xs={12}>
        <Form onSubmit={handleSubmit(onLogin)}>
          <LoginFormFields hassPassport={hasPassport} control={control} />
          <div className="pt-4">
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              text={t(`${prefix}.loginBtn`)}
              fullwidth
            />
          </div>
        </Form>
      </Col>
      {/*
      <Col xs={12} className="pb-1 text-center">
        <div className="mb-0 text-center text-underline color-primary">
          {t(`${prefix}.howToLogin`)}
        </div>
      </Col>
      */}
    </Row>
  );
};

export default LoginForm;
