import { CheckInput, Select, TextInput } from '@octano/global-ui';
import { Fragment, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useParameters } from '../../../hooks/useParameters';
import { useValidations } from '../../../hooks/useValidations';
import {
  RELATIONSHIP_OWN_SUSTAINER,
  SustainerType,
  relationshipOptions,
  sustainerTypeOptions,
} from '../../../utils/selectOptions';
import { useTuitionProcess } from '../../../views/TuitionProcess/TuitionProcessContext';
import { SectionTitle } from '../../text';

export default function DynamicInformation({
  sustainerPassportAvailable,
  postulantHasPassport,
}: {
  sustainerPassportAvailable: boolean;
  postulantHasPassport: boolean;
}) {
  const { t } = useTranslation();
  const { countryOptions } = useParameters();
  const { prefix: translationPrefix } = useTuitionProcess();
  const { control, watch, setValue } = useFormContext<any>();
  const { msgValidations, validateRut, validateTextNotEmpty } =
    useValidations();
  const prefix = `${translationPrefix}.sustainer.personalInformation`;

  const hasPassport = watch('hasPassport');
  const sustainer = watch('sustainerType');
  const ownSustainer = watch('ownSustainer');

  useEffect(() => {
    if (ownSustainer) {
      setValue('hasOptionalValues', true);
      setValue('sustainerType', sustainerTypeOptions[0]);
    } else {
      setValue('hasOptionalValues', false);
      setValue('rut', undefined);
    }
  }, [ownSustainer, setValue]);

  useEffect(() => {
    if (hasPassport) {
      setValue('rut', undefined);
    }
  }, [hasPassport, setValue]);

  const filteredRelationshipOptions = useMemo(
    () =>
      relationshipOptions.filter(
        (row) =>
          (ownSustainer && row.value === RELATIONSHIP_OWN_SUSTAINER) ||
          row.value !== RELATIONSHIP_OWN_SUSTAINER,
      ),
    [ownSustainer],
  );

  const requestPassport = useMemo(() => {
    if (!sustainerPassportAvailable) {
      return false;
    }
    return ownSustainer ? postulantHasPassport : hasPassport;
  }, [
    sustainerPassportAvailable,
    ownSustainer,
    postulantHasPassport,
    hasPassport,
  ]);

  const fields = useMemo(() => {
    if (sustainer?.value === SustainerType.NaturalPerson)
      return (
        <Fragment key="naturaPerson">
          <Col className="pb-3" xs={12} lg={4}>
            <Select
              name="relationship"
              label={t(`${prefix}.relationship`)}
              options={filteredRelationshipOptions}
              isSearchable={false}
              control={control}
              disabled={ownSustainer === true}
              shouldUnregister={true}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="occupation"
              label={t(`${prefix}.occupation`)}
              control={control}
              rules={{ required: msgValidations.required }}
            />
          </Col>

          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="names"
              label={t(`${prefix}.names`)}
              control={control}
              readOnly={Boolean(ownSustainer)}
              shouldUnregister={true}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="paternalLastName"
              label={t(`${prefix}.paternalLastName`)}
              control={control}
              readOnly={Boolean(ownSustainer)}
              shouldUnregister={true}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="maternalLastName"
              label={t(`${prefix}.maternalLastName`)}
              control={control}
              readOnly={Boolean(ownSustainer)}
              shouldUnregister={true}
              rules={{ required: msgValidations.required }}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <Row>
              {!requestPassport ? (
                <Col xs={12}>
                  <TextInput
                    name="rut"
                    label={t(`${prefix}.rut`)}
                    control={control}
                    formatter="rut"
                    shouldUnregister={true}
                    readOnly={Boolean(ownSustainer)}
                    rules={{
                      validate: {
                        required: validateTextNotEmpty,
                        rut: validateRut,
                      },
                    }}
                  />
                </Col>
              ) : (
                <>
                  <Col xs={12} md={6} className="pr-md-1">
                    <Select
                      name="passportCountry"
                      label={t(`${prefix}.passportCountry`)}
                      control={control}
                      options={countryOptions}
                      shouldUnregister={true}
                      isClearable={false}
                      disabled={ownSustainer === true}
                    />
                  </Col>
                  <Col xs={12} md={6} className="pl-md-1">
                    <TextInput
                      name="passportNumber"
                      label={t(`${prefix}.passportNumber`)}
                      control={control}
                      shouldUnregister={true}
                      readOnly={ownSustainer === true}
                    />
                  </Col>
                </>
              )}
              {sustainerPassportAvailable && (
                <Col xs={12} className="mb-n5">
                  <CheckInput
                    name="hasPassport"
                    label={t(`${prefix}.checkPassport`)}
                    disabled={ownSustainer}
                    control={control}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Fragment>
      );
    else if (sustainer?.value === SustainerType.LegalPerson)
      return (
        <Fragment key="legalPerson">
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="businessName"
              label="Razón social"
              control={control}
              disabled={ownSustainer}
              rules={{ required: msgValidations.required }}
              shouldUnregister={true}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="rut"
              label={t(`${prefix}.rut`)}
              control={control}
              formatter="rut"
              disabled={ownSustainer}
              shouldUnregister={true}
              rules={{
                validate: {
                  required: validateTextNotEmpty,
                  rut: validateRut,
                },
              }}
            />
          </Col>
        </Fragment>
      );
    else return null;
  }, [
    control,
    countryOptions,
    filteredRelationshipOptions,
    msgValidations.required,
    ownSustainer,
    prefix,
    requestPassport,
    sustainerPassportAvailable,
    sustainer?.value,
    t,
    validateRut,
    validateTextNotEmpty,
  ]);

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12}>
          <CheckInput
            name="ownSustainer"
            label={t(`${prefix}.ownSustainer`)}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} md={4}>
          <Select
            name="sustainerType"
            label={t(`${prefix}.sustainerType`)}
            options={sustainerTypeOptions}
            isSearchable={false}
            control={control}
            disabled={ownSustainer === true}
          />
        </Col>
        {fields}
      </Row>
    </Fragment>
  );
}
