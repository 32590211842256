import { useCallback } from "react";
import { Button, Icon, Modal } from "@octano/global-ui";
import { IconName } from "@octano/global-ui/dist/components/Icon/IconPaths";
import { Redirect, Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { PathsLayouts } from "../config/routes";
import { useUserState } from "../hooks/useUserState";
import RoutesByLayout from "../components/routes/RoutesByLayout";
import { useTranslation } from "react-i18next";
import TuitionProcessProvider, {
  TuitionProcessContextState,
} from "../views/TuitionProcess/TuitionProcessContext";

import { ReactComponent as AddemicProductLogo } from "../assets/addemic/logo_expanded_white.svg";
import { IS_ADDEMIC } from "../config/constants";

type MenuOptionType = {
  icon: keyof typeof IconName;
  name: string;
  action: () => void;
};
const TutionProcessLayout = () => {
  const prefix = "menuOption";
  const { isLogged, resetUserState, isSessionExpired, setIsSessionExpired } =
    useUserState();
  const { t } = useTranslation();

  const menuOptions: MenuOptionType[] = [
    {
      icon: "log_out",
      name: t(`${prefix}.logout`),
      action: () => resetUserState(),
    },
  ];

  const returnToLogin = () => {
    resetUserState();
  };

  const onError = useCallback<TuitionProcessContextState["onError"]>(
    (code, onUnknown) => {
      if (code === "AUTHENTICATION") {
        setIsSessionExpired(true);
      } else {
        onUnknown && onUnknown();
      }
    },
    [setIsSessionExpired]
  );

  if (!isLogged) {
    return <Redirect to={PathsLayouts.auth} />;
  }
  return (
    <>
      <div className="g-navbar">
        <div className="g-brand-logo">
          {IS_ADDEMIC ? (
            <AddemicProductLogo style={{ maxHeight: 30 }} />
          ) : (
            <img
              src="/tenant/logo_expanded_white.svg"
              alt="tenant_logo"
              style={{ maxHeight: 90 }}
            />
          )}
        </div>

        <div className="g-items-container">
          {menuOptions?.map((option, i) => (
            <Link
              className={`g-item text-decoration-none`}
              onClick={option.action}
              key={`menu${i}`}
              to="/auth/"
            >
              {option.icon && (
                <div className="pr-0 pr-sm-2">
                  <Icon name={option.icon} size="12px" />
                </div>
              )}
              <span className="d-none d-sm-block">{option.name}</span>
            </Link>
          ))}
        </div>
      </div>
      <TuitionProcessProvider prefix="tuitionProcess" onError={onError}>
        <Container className="pt-4 pb-5">
          <RoutesByLayout pathLayout={PathsLayouts.tuitionProcess} />
        </Container>
      </TuitionProcessProvider>

      <Modal
        isOpen={isSessionExpired}
        toggle={returnToLogin}
        closeOnBackdrop={false}
      >
        <Row className="text-center">
          <Col xs={12} className="pt-2">
            <Icon name="alert" size="56px" color="secondary" />
          </Col>
          <Col xs={12} className="pt-4 pb-3">
            <span className="fs-22 text-dark">{t(`sessionExpired.title`)}</span>
          </Col>
          <Col xs={12}>
            <p className="fs-18 fw-300">{t(`sessionExpired.body`)}</p>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col xs={12} sm={10} className="pb-2 px-3 px-sm-2 mx-auto">
            <Button
              onClick={returnToLogin}
              text={t(`sessionExpired.button`)}
              fullwidth
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default TutionProcessLayout;
