import { Icon } from "@octano/global-ui";
import { Trans, useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";

type LoadingProps = {
  textBody?: string;
  insideCard?: boolean;
};

const Loading = ({ textBody, insideCard = false }: LoadingProps) => {
  const { t } = useTranslation();

  const Content = () => (
    <div style={{ maxWidth: "400px", alignSelf: "center" }}>
      <Row className="text-center py-4 px-3">
        <Col xs={12}>
          <Icon className="spin" name="circle" color="secondary" size="40px" />
        </Col>
        <Col xs={12} className="py-4">
          <span className="fs-20 text-medium">
            <Trans t={t}>{textBody || t(`common.loading.body`)}</Trans>
          </span>
        </Col>
      </Row>
    </div>
  );

  if (insideCard) {
    return (
      <Card className="py-4">
        <Content />
      </Card>
    );
  } else {
    return <Content />;
  }
};

export default Loading;
