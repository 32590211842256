import React, { useReducer, createContext, FunctionComponent } from "react";
import userReducer, { initialState } from "../reducers/userReducer";
import { UserContextType } from "../types/userTypes";

export const UserContext = createContext({} as UserContextType);

const UserProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
