import {
  addToast,
  Button,
  SingleCardContainer,
  TextInput,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { getCertificateInfoRequest } from '../../api/requests/certificates';
import DisplayError from '../../components/info/DisplayError';
import Loading from '../../components/info/Loading';
import { PathsLayouts } from '../../config/routes';
import useQuery from '../../hooks/useQuery';
import { useValidations } from '../../hooks/useValidations';
import { CertificateType } from '../../types/certificateTypes';

import { ReactComponent as AddemicProductLogo } from '../../assets/addemic/logo_expanded.svg';
import { IS_ADDEMIC } from '../../config/constants';

export interface ValidationFormFields {
  code: string;
}

const ValidationForm = () => {
  const prefix = 'CertificateValidation';
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const { validateTextNotEmpty } = useValidations();

  const queryParams = useQuery();
  const code = useMemo(() => queryParams.get('code') || '', [queryParams]);

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue,
  } = useForm<ValidationFormFields>({
    mode: 'onSubmit',
  });

  const getCertificateInfo = useCallback(
    async (codeValue: string) => {
      setIsLoading(true);
      const { data, error } = await getCertificateInfoRequest(codeValue);
      if (data) {
        const certifcate: CertificateType = {
          duration: data.documentExpirationAt,
          status: data.documentStatus,
          studentName: data.studentName,
          rut: data.studentRun,
          studyPlan: data.studyPlanVersionOfferName,
          period: data.periodName,
          certificate: data.documentName,
          invoice: data.documentfolio,
          code: codeValue,
          documentUrl: data.documentUrl,
        };
        history.push(
          `${PathsLayouts.certificateValidation}/detalle`,
          certifcate,
        );
      } else if (error) {
        if (error.data?.statusCode === 404) {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.notFound`),
          });
        } else {
          setErrorLoading(true);
        }
      }
      setIsLoading(false);
    },
    [history, t],
  );

  const onSubmit = (data: ValidationFormFields) => {
    getCertificateInfo(data.code);
  };

  useEffect(() => {
    if (code) {
      setValue('code', code);
    }
  }, [code, setValue]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={t(`${prefix}.error`)}
        retryAction={handleSubmit(onSubmit)}
        loadingAction={isLoading}
      />
    );
  }
  if (isLoading) {
    return <Loading insideCard />;
  }

  return (
    <SingleCardContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="mr-5 ml-5">
              {IS_ADDEMIC ? (
                <AddemicProductLogo style={{ maxHeight: 30 }} />
              ) : (
                <img
                  src="/tenant/logo_expanded_white.svg"
                  alt="tenant_logo"
                  style={{ maxHeight: 90 }}
                />
              )}
            </div>
            <p className="text-center border-top border-primary mt-4 pt-5 fs-22 text-dark">
              {t(`${prefix}.title`)}
            </p>
            <p className="text-center mt-3 mb-4 fs-18 text-light">
              {t(`${prefix}.desc`)}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-4">
            <TextInput
              name="code"
              label={t(`${prefix}.code`)}
              control={control}
              rules={{ validate: validateTextNotEmpty }}
              tooltip={t(`${prefix}.codeTooltip`)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Button
              type="submit"
              text={t(`${prefix}.validate`)}
              loading={isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </form>
    </SingleCardContainer>
  );
};

export default ValidationForm;
