import { addToast } from '@octano/global-ui';
import { ReactNode, useMemo } from 'react';
import { Card, Col, Row } from 'reactstrap';

import { saveSustainer } from '../../../../api/requests/tuitionProcess';
import DynamicForm from '../../../../components/DynamicForm/DynamicForm';
import DynamicContact from '../../../../components/DynamicForm/parts/DynamicContact';
import DynamicInformation from '../../../../components/DynamicForm/parts/DynmicInformatation';
import HeaderStudentFullName from '../../../../components/tuitionProcess/HeaderStudentFullName';
import StepBackButton from '../../../../components/tuitionProcess/StepBackButton';
import { useStepState } from '../../../../hooks/useStepState';
import {
  RELATIONSHIP_NO_FAMILY,
  RELATIONSHIP_OWN_SUSTAINER,
  SustainerType,
  relationshipOptions,
  sustainerTypeOptions,
} from '../../../../utils/selectOptions';
import { useTuitionProcess } from '../../../TuitionProcess/TuitionProcessContext';
import SustainerFileLoader from './parts/SustainerFileLoader';
import { clean } from 'rut.js';

const SustainerFileNoSuaStep = ({ children }: { children?: ReactNode }) => {
  //Custom Hooks
  const { prefix: rootPrefix, onError } = useTuitionProcess();
  const { nextStep } = useStepState();

  const filteredRelationshipOptions = useMemo(
    () =>
      relationshipOptions.filter(
        (row) =>
          (true && row.value === RELATIONSHIP_OWN_SUSTAINER) ||
          row.value !== RELATIONSHIP_OWN_SUSTAINER,
      ),
    [],
  );
  //Step Prefix
  const prefix = useMemo(() => `${rootPrefix}.sustainer`, [rootPrefix]);

  //Submit form function
  const onSubmit = async (values: any) => {
    let responses = Object.entries(values)
      .map(([key, value]) => ({
        fieldName: key,
        value,
      }))
      .filter(({ value }) => value);
    /**
     * Se formatea el rut y siempre va a ser obligatorio en el formulario,
     * aún así igual validaré el index
     */
    const rutIndex = responses.findIndex((row) => row.fieldName === 'rut');
    if (rutIndex !== -1) {
      responses[rutIndex].value = clean(values.rut);
    }
    /**
     * en caso de ser persona  juridica se rellena esa data con guiones
     */
    if (values.sustainerType?.value === SustainerType.LegalPerson) {
      [
        'maternalLastName',
        'paternalLastName',
        'ocupation',
        'relationship',
      ].forEach((key) => {
        responses.push({
          fieldName: key,
          value: key === 'relationship' ? RELATIONSHIP_NO_FAMILY : '-',
        });
      });
    }
    /**
     * si el país es chile igual mando location y custom city
     */
    if (String(values.country?.label).toLowerCase().includes('chile')) {
      responses.push(
        { fieldName: 'location', value: values.commune?.label },
        { fieldName: 'customCity', value: values.city?.label },
      );
    }

    const { error } = await saveSustainer({
      responses,
    });

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: 'Ha ocurrido un error al guardar la información',
      });
    } else {
      addToast({
        icon: 'success',
        color: 'success',
        text: 'Datos guardados correctamente',
      });
      nextStep();
    }
  };

  const sectionComponents = (
    sustainerPassportAvailable: boolean,
    postulantHasPassport: boolean,
  ) => ({
    sustainerInformation: (
      <DynamicInformation
        key={Math.floor(Math.random() * 100)}
        sustainerPassportAvailable={sustainerPassportAvailable}
        postulantHasPassport={postulantHasPassport}
      />
    ),
    academicContact: (
      <DynamicContact
        type="sustainerFile"
        key={Math.floor(Math.random() * 100)}
        prefix={`${prefix}.contact`}
        disabled={false}
        hasEmail
      />
    ),
  });

  return (
    <Card className="px-4 px-md-5 py-4">
      <SustainerFileLoader {...{ onError }}>
        {({ sustainerPassportAvailable, defaultValues, ownSustainer }) => {
          return (
            <>
              <Row className="pb-4 pb-md-0">
                <Col xs={12}>
                  <StepBackButton />
                </Col>
                <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
                  <HeaderStudentFullName prefix={prefix} />
                </Col>
              </Row>
              <DynamicForm
                dynamicFormId={2}
                defaultValues={defaultValues}
                optionalValues={ownSustainer}
                sectionComponents={sectionComponents(
                  sustainerPassportAvailable,
                  Boolean(ownSustainer.passportNumber),
                )}
                options={{
                  relationship: filteredRelationshipOptions,
                  sustainerType: sustainerTypeOptions,
                }}
                onSubmit={onSubmit}
              />
              {children}
            </>
          );
        }}
      </SustainerFileLoader>
    </Card>
  );
};

export default SustainerFileNoSuaStep;
