import { Icon } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import { requestWaitingListInformation } from '../../../api/requests/tuitionProcess';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { useTuitionProcessStatus } from './StudentFile/parts/TuitionProcessStatusLoader';

type WaitingListInfo = {
  studyPlan: string;
  campusName: string;
  scheduleName: string;
  postulantName: string;
  postulantEmail: string;
  contactEmail: string;
};

const WaitingList = () => {
  const { t } = useTranslation();
  const prefix = 'tuitionProcessNoSua.waitingList';

  const [info, setInfo] = useState<WaitingListInfo>();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const { data: TuitionProcess } = useTuitionProcessStatus();

  const getInformation = useCallback(async () => {
    setLoading(true);
    const { data, error } = await requestWaitingListInformation(
      TuitionProcess?.admission?.admissionId,
    );
    if (data) {
      setInfo({
        studyPlan: data.studyPlan.name,
        campusName: data.studyPlan.campus.name,
        scheduleName: data.studyPlan.schedule.name,
        postulantName: data.postulantName,
        postulantEmail: data.postulantEmail,
        contactEmail: data.helpEmail,
      });
    }
    if (error) {
      setErrorLoading('errorUnexpected');
    }
    setLoading(false);
  }, [setLoading, TuitionProcess?.admission?.admissionId, setErrorLoading]);

  useEffect(() => {
    getInformation();
  }, [getInformation]);

  if (loading && !errorLoading) {
    return <Loading insideCard textBody={'Cargando...'} />;
  }
  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        title={t('common.displayError.title')}
        textBody={t(`common.displayError.${errorLoading}`)}
        retryAction={getInformation}
        loadingAction={loading}
      />
    );
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row
        className="text-center mx-auto w-100 py-4"
        style={{ maxWidth: '500px' }}
      >
        <Col xs={12} className="pb-2">
          <Icon name="waiting" size="52px" color="secondary" />
        </Col>
        <Col xs={12} className="pt-4 text-dark fw-400">
          <p className="fs-22 ">{t(`${prefix}.title`)}</p>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300 lh-30">
            <Trans t={t}>
              {t(`${prefix}.body`, {
                studyPlan: info?.studyPlan,
                campus: info?.campusName,
                schedule: info?.scheduleName,
              })}
            </Trans>
          </p>
        </Col>
        <Col xs={12} className="justify-content pb-3">
          <div
            className="mx-auto text-left box-outlined"
            style={{ maxWidth: '350px' }}
          >
            <p className="text-secondary mb-0 fs-18">
              {t(`${prefix}.name`)}:{' '}
              <span className="fw-600">{info?.postulantName}</span>
            </p>
            <p className="text-secondary mb-0 fs-18 text-break">
              {t(`${prefix}.email`)}:{' '}
              <span className="fw-600">{info?.postulantEmail}</span>
            </p>
          </div>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300 lh-30">
            <Trans
              i18nKey={`${prefix}.helpText`}
              values={{ contactEmail: info?.contactEmail }}
              components={{ emailLink: <span className="text-link" /> }}
            />
          </p>
          <p className="fs-18 fw-300 lh-30">{t(`${prefix}.close`)}</p>
        </Col>
      </Row>
    </Card>
  );
};

export default WaitingList;
