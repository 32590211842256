import { baseURL } from '../../../../api/api';
import { TUITION_PROCESS } from '../../../../api/endpoints';
import request, {
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../api/request';
import {
  AuthenticationError,
  requestAuth,
} from '../../../../api/requests/tuitionProcess';
import { GatewayPaymentLinkResponse, GatewayPaymentResponse } from './types';

export type GetPaymentResultData = GatewayPaymentResponse;

export type GetPaymentResultError =
  | { code: 'QUOTA_EXCEEDED' }
  | AuthenticationError
  | AxiosResultDefaultError;

export type GetPaymentResult = AxiosResult<
  GetPaymentResultData,
  GetPaymentResultError
>;

export async function getPaymentData(
  TBK_TOKEN?: string,
): Promise<GetPaymentResult> {
  const url = TUITION_PROCESS.POSTULANT_PAYMENT_STEP;
  const result: GetPaymentResult = await requestAuth<GatewayPaymentResponse>(
    url,
    {
      params: {
        TBK_TOKEN,
      },
    },
  );
  if (result.error) {
    const resultError = result.error;
    if (
      resultError.code === 'HTTP_ERROR' &&
      resultError.data?.code === 'QUOTA_EXCEEDED'
    ) {
      return { error: { code: 'QUOTA_EXCEEDED' } };
    }
  }
  return result;
}

/**
 * Obtiene el token para poder obtener documento para firmar la documentación
 * @returns string
 */
export const voucherToken = () => {
  return request<string>(TUITION_PROCESS.POSTULANT_PAYMENT_VOUCHER_TOKEN, {
    method: 'post',
  });
};

export const voucherPreview = (token: string): string => {
  return `${baseURL}${TUITION_PROCESS.POSTULANT_PAYMENT_VOUCHER}?token=${token}`;
};

export const generatePaymentLink = (return_url: string) => {
  const url = TUITION_PROCESS.POSTULANT_GENERATE_PAYMENT_LINK;
  return requestAuth<GatewayPaymentLinkResponse>(url, {
    method: 'POST',
    data: {
      return_url,
    },
  });
};

export const finishPaymentProcess = () => {
  const url = TUITION_PROCESS.POSTULANT_FINISH_PAYMENT_PROCESS;
  return requestAuth<GatewayPaymentLinkResponse>(url, {
    method: 'PUT',
  });
};
