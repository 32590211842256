import { useReducer, createContext } from "react";
import stepReducer, { initialState } from "../reducers/stepReducer";
import { StepContextType } from "../types/stepsTypes";

export const StepContext = createContext({} as StepContextType);

const StepProvider = (props: any) => {
  const [state, dispatch] = useReducer(stepReducer, initialState);

  return (
    <StepContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StepContext.Provider>
  );
};

export default StepProvider;
