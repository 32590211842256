import { Button, Icon } from '@octano/global-ui';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useCallback, useMemo } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { getStatusTuitionProcess } from '../../../../../api/requests/tuitionProcess';
import { useStepState } from '../../../../../hooks/useStepState';
import { useUserState } from '../../../../../hooks/useUserState';

interface LoginWithPassportProps {
  email?: string;
  phone?: string;
}
export default function LoginWithPassport(props: LoginWithPassportProps) {
  const { prevStep } = useStepState();
  const { postulationDetail, setPostulationDetail } = useUserState();

  /**
   * Cuando se matricula con el flujo original y selecciona carrera en el caso de caer en una modalidad donde no se permite  pasapporte
   * le actualizo la data del postulationDetail, por si retrocede y cambia de carrera.
   */
  const getStatus = useCallback(async () => {
    const fetchData = async () => {
      const { data } = await getStatusTuitionProcess();
      if (data) {
        setPostulationDetail(
          data.postulationDetailId && data.admission
            ? {
                id: data.postulationDetailId,
                ...data.admission,
                admissionMethod: data.admissionMethod,
                status: data.status,
              }
            : null,
        );
        prevStep();
      }
    };

    if (!postulationDetail) {
      await fetchData();
    } else {
      prevStep();
    }
  }, [postulationDetail, prevStep, setPostulationDetail]);

  const phone = useMemo(
    () => parsePhoneNumber(props.phone ?? '+5699999999').formatInternational(),
    [props.phone],
  );

  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="text-center mx-auto w-100 py-4">
        <Col xs={12} className="pb-2">
          <Icon name="information" size="52px" color="secondary" />
        </Col>
        <Col xs={12} className="pt-4 text-dark fw-400">
          <p className="fs-22 ">
            Este programa debe ser inscrito de manera presencial .
          </p>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300 lh-30">
            Para continuar con la matrícula debes dirgirte al centro de estudios
            Levinia Manfredini o selecciona un curso online. También puedes
            contactarte al teléfono <a href={`tel:${phone}`}>{phone}</a> o
            mediante correo electrónico
          </p>
        </Col>
      </Row>

      <div className="d-flex flex-column align-items-center" style={{ gap: 8 }}>
        <Button
          text="cambiar selección"
          style={{ width: 300 }}
          onClick={() => getStatus()}
        />
        <a
          className="g-button false  btn btn-outline-primary btn-lg "
          style={{ width: 300 }}
          href={`mailto:${props.email}?Subject=Matrícula estudiantes del extranjero&Body=Buenos%20d%C3%ADas%2C%20%0A%20%0AMe%20interesa%20matricularme%20a%20un%20curso%20presencial%2Fsemipresencial%20en%20su%20Centro%20de%20Estudios.%20Sin%20embargo%2C%20al%20ser%20de%20otro%20pa%C3%ADs%20y%20no%20disponer%20de%20carnet%20de%20identidad%20chileno%2C%20me%20pongo%20en%20contacto%20con%20ustedes.%20%0AAqu%C3%AD%20dejo%20la%20informaci%C3%B3n%20solicitada%20y%20quedo%20atent%40%20a%20sus%20comentarios%21%20%0A%20%0ACurso%20deseado%3A%20%0ANombre%28s%29%3A%0AApellido%28s%29%3A%0AFecha%20de%20Nacimiento%3A%20%0APa%C3%ADs%20de%20origen%3A%20%0AN%C3%BAmero%20de%20pasaporte%3A%20%0AM%C3%A9todo%20de%20pago%20deseado%3A%0ATipo%20de%20pago%20deseado%20%28Contado%20o%20n%20cuotas%29%3A%20%0A%20%0ASaludos%20cordiales%2C`}
        >
          <div className="pt-2">
            <span className="ml-2 text-uppercase">
              Enviar Correo electrónico
            </span>
          </div>
        </a>
      </div>
    </Card>
  );
}
