import { useTranslation } from "react-i18next";
import { useUserState } from "../../hooks/useUserState";

const HeaderStudentFullName = ({ prefix }: { prefix: string }) => {
  const { t } = useTranslation();
  const { fullName } = useUserState();

  return (
    <>
      <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
      <br />
      <span className="fs-18 text-primary fw-600">{fullName}</span>
    </>
  );
};

export default HeaderStudentFullName;
