import { addToast, Button } from '@octano/global-ui';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import {
  getPreviewDocument,
  tokenSignPreviewDocument,
} from '../../../../api/requests/tuitionProcess';
import { SectionTitle } from '../../../../components/text';
import HeaderStudentFullName from '../../../../components/tuitionProcess/HeaderStudentFullName';
import StepBackButton from '../../../../components/tuitionProcess/StepBackButton';
import { useStepState } from '../../../../hooks/useStepState';
import { useUserState } from '../../../../hooks/useUserState';
import { RequestedDocument } from '../../../../types/signDocumentationTypes';
import { QuotaExceedModal } from '../shared/QuotaExceedModal';
import DocumentCard from './DocumentCard';
import { verifySignDocumentation } from './requests';

/**
 * Componente que corresponde a la vista principal del paso de contrato y subida de archivos
 */
const DocumentationNoSua = ({
  children,
  requestedDocuments: requestedDocumentsProp,
}: {
  children?: ReactNode;
  requestedDocuments: RequestedDocument[];
}) => {
  const prefix = 'tuitionProcessNoSua.documentation';
  const { t } = useTranslation();
  const { setIsSessionExpired } = useUserState();
  const { nextStep } = useStepState();

  const [requestedDocuments, setRequestedDocuments] = useState<
    RequestedDocument[]
  >(requestedDocumentsProp);

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [showQuotaExceed, setShowQuotaExceed] = useState<boolean>(false);

  useEffect(() => window.scrollTo(0, 0));

  const onSubmit = async () => {
    if (requestedDocuments.some((r) => r.document.required && !r.uploaded)) {
      setShowErrors(true);
    } else {
      setShowErrors(false);
      const { error } = await verifySignDocumentation();
      if (!error) {
        nextStep();
      } else if (error && error.code === 'AUTHENTICATION') {
        setIsSessionExpired(true);
      } else if (error && error.code === 'QUOTA_EXCEEDED') {
        setShowQuotaExceed(true);
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.nextStepError`),
        });
      }
    }
  };

  const updateState = useCallback(
    (
      property: 'downloaded' | 'uploaded',
      requestedDocument: RequestedDocument,
    ) => {
      setRequestedDocuments((requestedDocuments) => {
        const index = requestedDocuments.findIndex(
          (r) => r === requestedDocument,
        );
        if (index < 0) {
          return [...requestedDocuments];
        }
        const newRequestedDocuments = [...requestedDocuments];
        const newRequestedDocument: RequestedDocument = {
          ...requestedDocument,
          [property]: true,
        };
        newRequestedDocuments[index] = newRequestedDocument;
        return newRequestedDocuments;
      });
    },
    [],
  );

  const setDownloaded = useCallback(
    (requestedDocument: RequestedDocument) =>
      updateState('downloaded', requestedDocument),
    [updateState],
  );
  const setUploaded = useCallback(
    (requestedDocument: RequestedDocument) =>
      updateState('uploaded', requestedDocument),
    [updateState],
  );

  const downloadContract = useCallback(
    async (requestedDocument: RequestedDocument) => {
      const { data, error } = await tokenSignPreviewDocument(
        requestedDocument.document.code,
      );
      if (data && !error) {
        const url = getPreviewDocument(data);
        window.open(url);
        setDownloaded(requestedDocument);
      } else if (error) {
        if (error.code === 'HTTP_ERROR' && error.status === 401) {
          setIsSessionExpired(true);
        }
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.errors.download`),
        });
      }
    },
    [setIsSessionExpired, setDownloaded, t],
  );

  return (
    <>
      <Card className="px-4 px-md-5 py-4">
        <Row className="pb-4 pb-md-0">
          <Col xs={12}>
            <StepBackButton />
          </Col>
          <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
            <HeaderStudentFullName prefix={prefix} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.title`)} />
          </Col>
          <Col xs={12} className="pb-2">
            <p className="fs-18 text-light fw-300">
              {t(`${prefix}.description`)}
            </p>
          </Col>
        </Row>

        {/* Listado de documentos a adjuntar */}
        <Row className="mx-n2 align-items-end">
          {requestedDocuments.map((requestedDocument) => {
            const size = requestedDocument.document.generated ? 'md' : 'sm';
            return (
              <Col xs={12} md={6} lg={size === 'md' ? 6 : 3} className="mb-3">
                <DocumentCard
                  errorText={undefined}
                  onDownload={downloadContract}
                  onUploaded={setUploaded}
                  requestedDocument={requestedDocument}
                  requiredDocument={requestedDocuments?.find(
                    (e) =>
                      e?.document?.id === requestedDocument?.requiredDocumentId,
                  )}
                  size={size}
                  showErrors={showErrors}
                />
              </Col>
            );
          })}
        </Row>
        <Row className="pt-3 pb-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              text={t(`${prefix}.btnNext`)}
              onClick={onSubmit}
              fullwidth
            />
          </Col>
        </Row>
        {children}
      </Card>
      <QuotaExceedModal
        show={showQuotaExceed}
        toggleModal={() => console.log('toggle')}
      />
    </>
  );
};

export default DocumentationNoSua;
