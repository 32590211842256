import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal, Icon, Button, addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

export type RegistrationModalErrorMethods = {
  open: (message?: null | string | string[]) => void;
  close: () => void;
};

type RegistrationModalErrorProps = {
  hasPassport: boolean;
  loading?: boolean;
  prefix?: string;
  onBackToLogin?: () => void;
  onGoToWaitlist?: () => void;
};

const ERROR_CODES = [
  'USER_ALREADY_EXIST',
  'NON_ACTIVE_POSTULATION',
  'MINIMUN_AGE_FAILED',
  'QUOTA_EXCEEDED',
];

const RegistrationModalError = (
  {
    hasPassport,
    prefix = 'registration',
    onBackToLogin,
    onGoToWaitlist,
  }: RegistrationModalErrorProps,
  ref: React.Ref<RegistrationModalErrorMethods>,
) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [button, setButton] = useState<string>('');
  const [closeButton, setCloseButton] = useState<string>('');

  const handleClose = useCallback(() => {
    setCode('');
    setTitle('');
    setBody('');
    setButton('');
    setCloseButton('');
    setOpen(false);
  }, []);

  const handleOpen = useCallback(
    (message?: null | string | string[]) => {
      const error = Array.isArray(message) ? message[0] : message;

      if (error && ERROR_CODES?.includes(error)) {
        const values = {
          minimunAge: 17,
          accessType: hasPassport
            ? t(`${prefix}.form.passportNumber`)
            : t(`${prefix}.form.rut`),
        };

        setCode(error);
        setTitle(t(`${prefix}.errorsModals.${error}.title`, values));
        setBody(t(`${prefix}.errorsModals.${error}.body`, values));
        setButton(t(`${prefix}.errorsModals.${error}.button`, values));
        if (error === 'QUOTA_EXCEEDED') {
          setCode(t(`${prefix}.errorsModals.${error}.close`, values));
        }
        setOpen(true);
        return;
      }

      return addToast({
        icon: 'error',
        color: 'danger',
        text: t(`${prefix}.errors.DEFAULT`),
      });
    },
    [hasPassport, prefix, t],
  );

  const handleContinue = useCallback(() => {
    handleClose();
    if (code === 'NON_ACTIVE_POSTULATION') {
      !!onBackToLogin && onBackToLogin();
    }
    if (code === 'QUOTA_EXCEEDED') {
      !!onGoToWaitlist && onGoToWaitlist();
    }
  }, [code, handleClose, onBackToLogin, onGoToWaitlist]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal isOpen={open} toggle={handleClose} closeOnBackdrop={false}>
      <div className="text-center">
        <Icon className="mb-2" name="warning" size="52px" color="secondary" />
        <p className="pt-4 text-dark fw-400 fs-22">{title}</p>
        <p className="fs-18 fw-300">{body}</p>
        <Button
          type="button"
          className="mt-4"
          text={button}
          onClick={handleContinue}
          fullwidth
        />
        {!!closeButton?.trim() && (
          <Button
            type="button"
            className="mt-42"
            outlined
            text={button}
            onClick={handleClose}
            fullwidth
          />
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(RegistrationModalError);
