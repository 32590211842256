import { Redirect, useParams } from 'react-router-dom';
import { IS_SUA } from '../../config/constants';
import RegistrationForm from './parts/RegistrationForm';
import RegistrationNoSuaLoader from './parts/RegistrationNoSuaLoader';
import RegistrationWithCareerForm from './parts/RegistrationWithCareerForm';

const RegistrationNoSua = () => {
  const { studyPlanVersionOfferPeriodAdmissionId } = useParams<{
    studyPlanVersionOfferPeriodAdmissionId: string;
  }>();

  if (IS_SUA) {
    return <Redirect to="/auth" />;
  }

  if (studyPlanVersionOfferPeriodAdmissionId) {
    return (
      <RegistrationNoSuaLoader
        studyPlanVersionOfferPeriodAdmissionId={parseInt(
          studyPlanVersionOfferPeriodAdmissionId,
        )}
      >
        {({ data }) => {
          return <RegistrationWithCareerForm career={data} />;
        }}
      </RegistrationNoSuaLoader>
    );
  }

  return <RegistrationForm />;
};

export default RegistrationNoSua;
