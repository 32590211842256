import { useEffect } from 'react';
import { Card, Col, Row } from 'reactstrap';
import HeaderStudentFullName from '../../../../components/tuitionProcess/HeaderStudentFullName';
import StepBackButton from '../../../../components/tuitionProcess/StepBackButton';
import SustainerForm from './SustainerForm';
import SustainerLoader from './SustainerLoader';

const Sustainer = () => {
  const prefix = 'tuitionProcess.sustainer';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12}>
          <StepBackButton />
        </Col>
        <Col className="py-3" xs={12} lg={7} md={8} xl={9}>
          <HeaderStudentFullName prefix={prefix} />
        </Col>
      </Row>
      <SustainerLoader>
        {({ sustainerPassportAvailable, defaultValues, ownSustainer }) => (
          <SustainerForm
            sustainerPassportAvailable={sustainerPassportAvailable}
            defaultValues={defaultValues}
            dataOwnSustainer={ownSustainer}
          />
        )}
      </SustainerLoader>
    </Card>
  );
};

export default Sustainer;
