import { Button, Icon, IconNameType } from "@octano/global-ui";
import { Col, FormText, Row } from "reactstrap";

type BtnOptions = {
  text: string;
  action: () => void;
};

export type DocumentProps = {
  name: string;
  nameIcon: IconNameType;
  btnOpen: BtnOptions;
  btnDownload: BtnOptions;
  btnSign: BtnOptions;
  isSigned: boolean;
  error?: string;
};

/**
 * Contenedor de información de documento que debe ser firmado
 * en el paso "Firmar Documentación"
 */
const Document = ({
  name,
  nameIcon,
  btnOpen,
  btnDownload,
  btnSign,
  isSigned,
  error,
}: DocumentProps) => {
  return (
    <Col xs={12} lg={6}>
      <span className='text-uppercase fs-18 fw-600 text-secondary'>{name}</span>
      <div className='bg-light px-4 py-2 mt-2'>
        <Row className='py-2'>
          <Col
            className='text-center align-self-center py-3 py-md-1'
            xs={12}
            md={6}>
            <Icon name={nameIcon} size='95px' color='secondary' />
          </Col>
          <Col
            className='text-center px-5 px-md-3 align-self-end'
            xs={12}
            md={6}>
            <Button
              onClick={btnOpen.action}
              outlined
              size='sm'
              text={btnOpen.text}
              fullwidth
            />
            <Button
              icon='download'
              onClick={btnDownload.action}
              outlined
              size='sm'
              text={btnDownload.text}
              fullwidth
            />
            <Button
              icon={isSigned ? "check" : undefined}
              onClick={!isSigned ? btnSign.action : undefined}
              size='sm'
              text={btnSign.text}
              fullwidth
            />
          </Col>
        </Row>
      </div>
      <FormText className='g-input-error' color='danger'>
        {error || <>&nbsp;</>}
      </FormText>
    </Col>
  );
};

export default Document;
