import { addToast, Button, SelectOptionType } from "@octano/global-ui";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, FormProvider, DefaultValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "reactstrap";
import { clean } from "rut.js";
import { saveCosigner } from "../../../../api/requests/tuitionProcess";
import ContactSection from "../../../../components/form/ContactSection";
import { useStepState } from "../../../../hooks/useStepState";
import { useUserState } from "../../../../hooks/useUserState";
import PersonalInformation from "./PersonalInformation";

export type FieldsCosignerForm = {
  relationship: SelectOptionType | null;
  occupation: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  hasPassport: boolean;
  rut: string;
  passportNumber: string;
  passportCountry?: SelectOptionType;
  nationality: SelectOptionType | null;
  maritalStatus: SelectOptionType | null;
  checkSustainer: boolean;
  addressStreet: string;
  addressNumber: string;
  addressExtra: string;
  commune: SelectOptionType | null;
  region: SelectOptionType | null;
  phone: string;
  cellphone: string;
  email: string;
};

type CosignerFormProps = {
  defaultValues?: DefaultValues<FieldsCosignerForm>;
  dataSustainer?: FieldsCosignerForm;
};

/**
 * Formulario del paso "Datos Aval" del proceso de matrícula
 */
const CosignerForm = ({ defaultValues, dataSustainer }: CosignerFormProps) => {
  const { rut, setIsSessionExpired } = useUserState();
  const prefix = "tuitionProcess.cosigner";
  const { t } = useTranslation();
  const methods = useForm<FieldsCosignerForm>({
    mode: "onSubmit",
    defaultValues: defaultValues,
  });
  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    reset,
  } = methods;
  const { nextStep } = useStepState();
  const checkSustainer = watch("checkSustainer");

  const onCheckSustainer = useCallback(() => {
    setValue("rut", dataSustainer?.rut ?? "");
    setValue("names", dataSustainer?.names ?? "");
    setValue("paternalLastName", dataSustainer?.paternalLastName ?? "");
    setValue("maternalLastName", dataSustainer?.maternalLastName ?? "");
    setValue("addressStreet", dataSustainer?.addressStreet ?? "");
    setValue("addressNumber", dataSustainer?.addressNumber ?? "");
    setValue("addressExtra", dataSustainer?.addressExtra ?? "");
    if (dataSustainer?.region) {
      setValue("region", dataSustainer.region);
    }
    if (dataSustainer?.commune) {
      setValue("commune", dataSustainer.commune);
    }
    setValue("phone", dataSustainer?.phone ?? "");
    setValue("cellphone", dataSustainer?.cellphone ?? "");
    setValue("email", dataSustainer?.email ?? "");
  }, [dataSustainer, setValue]);

  const onChangeSustainerAsCosigner = useCallback(() => {
    if (checkSustainer) {
      onCheckSustainer();
    } else {
      reset();
    }
  }, [checkSustainer, onCheckSustainer, reset]);

  useEffect(() => {
    if (checkSustainer !== undefined) {
      onChangeSustainerAsCosigner();
    }
  }, [checkSustainer, onChangeSustainerAsCosigner]);

  const onError = useCallback(
    (error: any) => {
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      } else {
        addToast({
          icon: "error",
          color: "danger",
          text: t(`${prefix}.saveError`),
        });
      }
    },
    [setIsSessionExpired, t]
  );

  const onSubmit = async (values: FieldsCosignerForm) => {
    let saveValues = values;
    if (values.checkSustainer && dataSustainer) {
      // Para guardar los valores del sustentador como aval del alumno,
      // se mezcla los values con los datos de dataSustainer
      // ya que al dejar los inputs disabled react hook forms retorna los valores como undefined
      saveValues = { ...values, ...dataSustainer };
    }
    if (saveValues.commune) {
      const { data, error } = await saveCosigner({
        rut: saveValues?.rut ? clean(saveValues.rut) : "",
        names: saveValues.names,
        paternalLastName: saveValues.paternalLastName,
        maternalLastName: saveValues.maternalLastName,
        addressStreet: saveValues.addressStreet,
        addressNumber: saveValues.addressNumber,
        addressExtra: saveValues.addressExtra ?? null,
        communeId: +saveValues.commune?.value,
        phone: saveValues.phone ?? null,
        cellPhone: saveValues.cellphone,
        email: saveValues.email,
        passportCountry: saveValues.passportCountry?.value.toString(),
        passportNumber: saveValues.passportNumber,
      });

      if (data && error === false) {
        addToast({
          icon: "success",
          color: "success",
          text: t(`${prefix}.saveSuccess`),
        });
        nextStep();
      } else if (error) {
        onError(error);
      }
    }
  };

  const studentIsSustainer = useMemo(() => {
    // TODO: FALTA VALIDAR SI EL SUSTENTADOR ES EL POSTULANTE MEDIANTE EL PASAPORTE
    return clean(dataSustainer?.rut ?? "") === rut;
  }, [dataSustainer, rut]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation
          data={dataSustainer}
          disabledCheckSustainer={studentIsSustainer}
        />
        <ContactSection
          prefix={`${prefix}.contact`}
          disabled={checkSustainer}
        />
        <Row className="pb-5 pt-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              type="submit"
              text={t(`${prefix}.nextBtn`)}
              loading={isSubmitting}
              fullwidth
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CosignerForm;
