import { ReactElement, useCallback } from 'react';

import { AxiosResultDefaultError } from '../../../api/request';
import {
  AuthenticationError,
  refreshTokenTuitionProcess,
} from '../../../api/requests/tuitionProcess';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useUserState } from '../../../hooks/useUserState';
import { createFetchContext } from '../../../providers/FetchContextFactory';
import { setJWT } from '../../../utils/auth';

export interface TuitionsEnrollmentLoaderProps {
  children: (props: { refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  { accessToken: string },
  AuthenticationError | AxiosResultDefaultError
>();

export const RefreshTokenLoaderConsumer = FetchConsumer;

export const useRefreshTokenLoader = useFetch;

export default function RefreshTokenLoader({
  children,
}: TuitionsEnrollmentLoaderProps) {
  const request = useCallback(async () => {
    return refreshTokenTuitionProcess();
  }, []);

  const { setIsSessionExpired } = useUserState();

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 401) {
              setIsSessionExpired(true);
            }
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          setJWT(data.accessToken);
          return children({
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
