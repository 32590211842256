import { ReactElement, useCallback } from 'react';
import { Card } from 'reactstrap';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { createFetchContext } from '../../../../providers/FetchContextFactory';
import { RequestedDocument } from '../../../../types/signDocumentationTypes';
import { QuotaExceed } from '../shared/QuotaExceed';
import {
  getSignDocumentation,
  GetSignDocumentationResultData,
  GetSignDocumentationResultError,
} from './requests';

export interface BaseLoaderProps {
  children: (props: {
    requestedDocuments: RequestedDocument[];
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  GetSignDocumentationResultData,
  GetSignDocumentationResultError
>();

export const BaseLoaderConsumer = FetchConsumer;

/**
 *
 * Base Loader debe contener toda la data necesaria para que el formulario funcione.
 * opciones de select, settings, etc.
 */
export default function BaseLoader({ children }: BaseLoaderProps) {
  const request = useCallback(() => {
    return getSignDocumentation();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'QUOTA_EXCEEDED') {
              return (
                <Card className="p-4">
                  <QuotaExceed />
                </Card>
              );
            } else {
              return (
                <DisplayError
                  insideCard
                  textBody={error.code}
                  retryAction={refresh}
                  loadingAction={loading}
                />
              );
            }
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />;
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            requestedDocuments: data.requestedDocuments,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
