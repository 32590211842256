import { Loading } from '@octano/global-ui';
import { createContext, useEffect, useState } from 'react';
import { useParameters } from '../hooks/useParameters';
import { ParametersContextType } from '../types/parametersTypes';

export const ParametersContext = createContext({} as ParametersContextType);

const ParametersUnauthenticatedLoaderProvider = (props: any) => {
  const { loadCountries } = useParameters();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([loadCountries()]).then(() => {
      setLoading(false);
    });
  }, [loadCountries]);
  if (loading) {
    return (
      <div className="text-center">
        <Loading textBody="" className="m-auto" />
      </div>
    );
  }
  return props.children;
};

export default ParametersUnauthenticatedLoaderProvider;
