import { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Button, Icon, Modal } from '@octano/global-ui';
import { clean, validate } from 'rut.js';
import {
  loginTutionProcess,
  LoginTuitionProcessError,
} from '../../../api/requests/tuitionProcess';
import { AccessType, LoginTuitionProcessBody } from '../../../types/loginTypes';
import { LoginInputs } from '../../../components/loginForm/LoginFormFields';
import LoginForm from '../../../components/loginForm/LoginForm';
import { useUserState } from '../../../hooks/useUserState';
import { formatDate } from '../../../utils/dates';
import { setJWT } from '../../../utils/auth';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const formatData = (values: LoginInputs): LoginTuitionProcessBody => {
  const type: AccessType = values.hasPassport === true ? 'passport' : 'rut';
  return {
    type,
    rut: values.rut ? clean(values.rut) : '',
    passportNumber: values?.passportNumber,
    passportCountry: values?.passportCountry?.value.toString() || '',
    birthday: formatDate(values.birthday, 'YYYY-MM-DD'),
    processType: 'sin-sua',
  };
};

const LoginFormNoSuaProcess = () => {
  const prefix = 'loginTuitionProcess';
  const { t } = useTranslation();
  const { setIsLogged } = useUserState();
  const history = useHistory();

  const [errorLogin, setErrorLogin] = useState('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [failedLoginValues, setFailedLoginValues] =
    useState<LoginInputs | null>(null);

  const resolveError = (resultError: LoginTuitionProcessError) => {
    if (resultError.code === 'notFound') {
      setIsModalOpen(true);
    } else {
      setErrorLogin(
        resultError.code === 'invalidFields' ? 'invalidFields' : 'unknownError',
      );
    }
  };

  const onLogin = async (values: LoginInputs) => {
    if (!values.hasPassport && !validate(clean(values.rut))) {
      setErrorLogin('invalidRut');
      return;
    }
    const { data, error } = await loginTutionProcess(formatData(values));
    if (data) {
      setJWT(data.accessToken);
      setIsLogged(true);
    }
    if (error) {
      setFailedLoginValues(values);
      resolveError(error);
    }
  };

  const onPressRegistrationBtn = () => {
    setIsModalOpen(false);
    history.push('/auth/registration', failedLoginValues);
  };

  return (
    <>
      <LoginForm
        onLogin={onLogin}
        errorLogin={errorLogin}
        toggleError={() => setErrorLogin('')}
      />

      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        closeOnBackdrop={false}
      >
        <Row className="text-center">
          <Col xs={12} className="pb-2">
            <Icon name="information" size="52px" color="secondary" />
          </Col>
          <Col xs={12} className="pt-4 text-dark fw-400">
            <p className="fs-22">
              {t(`${prefix}.unregisteredUserModal.title`)}
            </p>
          </Col>
          <Col xs={12}>
            <p className="fs-18 fw-300">
              {t(`${prefix}.unregisteredUserModal.body`)}
            </p>
          </Col>
          <Col xs={12} className="px-5 pt-4">
            <Button
              text={t(`${prefix}.unregisteredUserModal.btnRegistration`)}
              onClick={onPressRegistrationBtn}
              fullwidth
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default LoginFormNoSuaProcess;
