import { Button, Icon, IconNameType } from "@octano/global-ui";
import { Col, Row } from "reactstrap";

export type DocumentItemProps = {
  name: string;
  nameIcon: IconNameType;
  btnText: string;
  actionBtn: () => void;
};

/**
 * Contenedor de información de documento del proceso de terminos y condiciones
 * Despliega el nombre del documento con su respectivo icono y un botón
 * para realizar una acción.
 */
const DocumentItem = ({
  name,
  nameIcon,
  btnText,
  actionBtn,
}: DocumentItemProps) => {
  return (
    <Col xs={12} lg={6} className='mb-3'>
      <span className='text-uppercase fs-18 fw-600 text-secondary'>{name}</span>
      <div className='bg-light p-4 mt-2'>
        <Row className='py-2'>
          <Col className='text-center' xs={12} md={6}>
            <Icon name={nameIcon} size='95px' color='secondary' />
          </Col>
          <Col
            className='text-center pt-4 px-5 px-md-3 align-self-end'
            xs={12}
            md={6}>
            <Button
              onClick={actionBtn}
              outlined
              size='md'
              text={btnText}
              fullwidth
            />
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default DocumentItem;
