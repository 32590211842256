import { TextInput, Select } from "@octano/global-ui";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import SectionTitle from "../../../../components/text/SectionTitle";
import { useValidations } from "../../../../hooks/useValidations";
import {
  HAS_INSURANCE,
  healthCareProviderOptions,
  healthCareSystemOptions,
  healthInsuranceOptions,
  HEALTH_CARE_OTHER,
  ISAPRE,
} from "../../../../utils/selectOptions";
import { FieldsStudentFile } from "./StudentFileForm";

/**
 * Sección de Sistema de salud
 */
const HealthCareSection = () => {
  const { control, watch } = useFormContext<FieldsStudentFile>();
  const prefix = "tuitionProcess.studentFile.healtCareSection";
  const { t } = useTranslation();
  const { msgValidations, validateTextNotEmpty, validateRut, validatePhone } =
    useValidations();
  const selectedHealthCareSystem = watch("healthCareSystem");
  const selectedHealthInsurance = watch("healthInsurance");

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="healthCareSystem"
            label={t(`${prefix}.healthCareSystem`)}
            options={healthCareSystemOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        {selectedHealthCareSystem?.value === ISAPRE && (
          <Col className="pb-3" xs={12} lg={4}>
            <Select
              name="healthCareProvider"
              label={t(`${prefix}.healthCareProvider`)}
              options={healthCareProviderOptions}
              isSearchable={false}
              control={control}
              shouldUnregister
              rules={{ required: msgValidations.required }}
            />
          </Col>
        )}
        {selectedHealthCareSystem?.value === HEALTH_CARE_OTHER && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="otherHealthCare"
              label={t(`${prefix}.otherHealthCare`)}
              control={control}
              shouldUnregister
              rules={{
                validate: {
                  notEmpty: validateTextNotEmpty,
                },
              }}
            />
          </Col>
        )}
        {selectedHealthCareSystem?.value && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="healthCareContributorID"
              label={t(`${prefix}.healthCareContributorID`)}
              control={control}
              formatter="rut"
              shouldUnregister
              rules={{
                validate: {
                  notEmpty: validateTextNotEmpty,
                  rut: validateRut,
                },
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="healthInsurance"
            label={t(`${prefix}.healthInsurance`)}
            options={healthInsuranceOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        {selectedHealthInsurance?.value === HAS_INSURANCE && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="healthInsuranceContributorID"
                label={t(`${prefix}.healthInsuranceContributorID`)}
                control={control}
                formatter="rut"
                shouldUnregister
                rules={{
                  validate: {
                    notEmpty: validateTextNotEmpty,
                    rut: validateRut,
                  },
                }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="phoneNumberHealthCare"
                label={t(`${prefix}.phoneNumber`)}
                tooltip={t(`${prefix}.tooltipPhoneNumber`)}
                control={control}
                formatter="phone"
                shouldUnregister
                rules={{
                  validate: validatePhone,
                }}
                maxLength={15}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default HealthCareSection;
