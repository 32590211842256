import { Button, Icon, Modal } from "@octano/global-ui";
import { ReactChild, Fragment, useState, useCallback } from "react";
import { Col, Row } from "reactstrap";

export interface ConfirmModalProps<T extends Array<any>> {
  title: string;
  body: string;
  confirm: string;
  cancel: string;
  onConfirmed: (...args: T) => void | Promise<void>;
  children: (props: { requestConfirm: () => void }) => ReactChild;
}

export default function ConfirmModal<T extends Array<any>>(
  props: ConfirmModalProps<T>
) {
  const {
    title,
    body,
    confirm,
    cancel,
    onConfirmed,
    children: childrenFunction,
  } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>();
  const onConfirmRequested = useCallback((...args: any[]) => {
    setOpen(true);
    setData(args);
  }, []);
  return (
    <Fragment>
      {childrenFunction({ requestConfirm: onConfirmRequested })}
      <Modal
        isOpen={open}
        toggle={() => setOpen(false)}
        closeOnBackdrop={false}
      >
        <Row className="text-center">
          <Col xs={12} className="pt-2">
            <Icon name="information" size="52px" color="secondary" />
          </Col>
          <Col xs={12} className="pt-4 pb-3">
            <span className="fs-22 text-dark">{title}</span>
          </Col>
          <Col xs={12}>
            <p className="fs-18 fw-300">{body}</p>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2">
            <Button
              onClick={() => setOpen(false)}
              text={cancel}
              outlined
              fullwidth
            />
          </Col>
          <Col xs={12} sm={6} className="pb-2 px-3 px-sm-2">
            <Button
              onClick={() => {
                onConfirmed(...data);
                setOpen(false);
              }}
              text={confirm}
              fullwidth
            />
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
}
