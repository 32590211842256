// Puede ser 1 o 0
export const IS_ADDEMIC = process.env.REACT_APP_IS_ADDEMIC
  ? Boolean(+process.env.REACT_APP_IS_ADDEMIC)
  : false;

export const JWT_KEY: string =
  process.env.REACT_APP_CAMPUS_JWT_KEY || "G3000_CAMPUS_JWT_KEY";

export const COMMIT_ID: string | null = process.env.REACT_APP_COMMIT_ID || null;

export const COMMIT_TIMESTAMP: number | null = process.env
  .REACT_APP_COMMIT_TIMESTAMP
  ? parseInt(process.env.REACT_APP_COMMIT_TIMESTAMP)
  : null;

export const IS_SUA: boolean =
  process.env.REACT_APP_SUA_PROCESS === "true" ? true : false;
