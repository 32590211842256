import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AxiosResult, AxiosResultDefaultError } from '../api/request';


export interface FetchContextState<
  Q,
  R,
  E extends { code: string; data?: any },
> {
  readonly loading: boolean;
  readonly data?: R;
  readonly error?: E;
  readonly query: Q;
  readonly updateQuery: (query?: Q) => void;
  readonly refresh: () => Promise<void>;
}

const contextDefaultValues: FetchContextState<any, any, any> = {
  loading: true,
  data: { data: [], total: 0, total_pages: 0 },
  error: undefined,
  query: {},
  updateQuery: () => {
    throw new Error('Update Query No implementado');
  },
  refresh: () => {
    throw new Error('refresh No implementado');
  },
};

export function createFetchContext<
  Q,
  R,
  E extends { code: string; data?: any } = AxiosResultDefaultError,
>() {
  const FetchContext =
    createContext<FetchContextState<Q, R, E>>(contextDefaultValues);

  function useFetch() {
    return useContext(FetchContext);
  }

  function FetchProvider({
    request,
    fetchImmediately: fetchImmediatelyProp = false,
    children,
    defaultQuery,
  }: {
    request: (query: Q) => Promise<AxiosResult<R, E>>;
    fetchImmediately?: boolean;
    children?: ReactNode;
    defaultQuery: Q;
  }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<R>();
    const [error, setError] = useState<E>();
    const [query, setQuery] = useState<Q>(defaultQuery);
    const initialized = useRef(false);
    const fetchImmediately = useRef(fetchImmediatelyProp);

    const fetchData = useCallback(
      async (query: Q) => {
        setLoading(true);
        const result = await request(query);
        if (result.error) {
          setError(result.error);
        } else {
          setError(undefined);
          setData(result.data ?? undefined);
        }
        setLoading(false);
      },
      [request],
    );

    useEffect(() => {
      if (fetchImmediately.current || initialized.current) {
        fetchData(query);
      }
      initialized.current = true;
    }, [fetchData, query]);

    const updateQuery = useCallback(
      (q?: Q) => {
        setQuery(q ?? defaultQuery);
      },
      [defaultQuery],
    );

    const refresh = useCallback(() => {
      return fetchData(query);
    }, [query, fetchData]);

    const value = useMemo(
      () => ({ loading, data, error: error, query, updateQuery, refresh }),
      [loading, data, error, query, updateQuery, refresh],
    );

    return (
      <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
    );
  }

  function FetchConsumer({
    children,
  }: {
    children: (props: FetchContextState<Q, R, E>) => ReactNode;
  }) {
    return <FetchContext.Consumer children={children} />;
  }

  return {
    FetchContext,
    useFetch,
    FetchProvider,
    FetchConsumer,
  };
}
