import { ReactElement, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResultDefaultError } from '../../../../../api/request';

import {
  AuthenticationError,
  getStatusTuitionProcess,
} from '../../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import { useUserState } from '../../../../../hooks/useUserState';
import { createFetchContext } from '../../../../../providers/FetchContextFactory';
import { TuitionProcessStatusResponse } from '../../../../../types/tuitionProcessResponseTypes';
import {
  PostulantPostulationState,
  PostulationDetailType,
} from '../../../../../types/userTypes';
import WaitingList from '../../WaitingList';
import Desisted from './Desisted';

export interface TuitionProcessStatusLoaderProps {
  children: (props: {
    postulationDetail: PostulationDetailType | null;
    student: {
      fullName?: string;
      rut?: string;
      postulantId?: number;
    };
    stepId?: string;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  TuitionProcessStatusResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export const TuitionProcessStatusLoaderConsumer = FetchConsumer;

export const useTuitionProcessStatus = useFetch;

/**
 * con el refresh retornado en el children forzaremos una actualización de los datos del postulante y de la postulación.
 */
export default function TuitionProcessStatusLoader({
  children,
}: TuitionProcessStatusLoaderProps) {
  const { postulationDetailId } = useParams<{
    postulationDetailId: string;
  }>();

  const request = useCallback(async () => {
    return getStatusTuitionProcess(postulationDetailId);
  }, [postulationDetailId]);

  const { setIsSessionExpired } = useUserState();

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 401) {
              setIsSessionExpired(true);
            }
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          /** Por alguna eventualidad un postulante puede desistir de una postulación porque seleccionó erroneamente su carrera
           * por lo tanto puede solicitar a un administrador que desista la postulación.
           * Cuando esto sucede se renderiza el componente Desisted el cual permite cambiar el plan de estudio al postulante.
           */
          if (data?.status === PostulantPostulationState.DESISTED) {
            return <Desisted refresh={refresh} />;
          }

          if (data?.status === PostulantPostulationState.IN_WAITING_LIST) {
            return <WaitingList />;
          }

          return children({
            postulationDetail:
              data?.postulationDetailId && data.admission
                ? {
                    id: data.postulationDetailId,
                    ...data.admission,
                    admissionMethod: data.admissionMethod,
                    status: data.status,
                  }
                : null,
            student: {
              fullName: data?.studentFullName,
              rut: data?.studentRut,
              postulantId: data?.postulantId,
            },
            stepId: data?.step,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
