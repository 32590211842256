import { useCallback, useEffect, useState } from "react";
import { Alert } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";
import { DefaultValues } from "react-hook-form";
import { getStudentFile } from "../../../../api/requests/tuitionProcess";
import {
  BackgroundSectionType,
  EducationalBackgroundType,
} from "../../../../types/studentFileSectionTypes";
import { useLoadingState } from "../../../../hooks/useLoadingState";
import DisplayError from "../../../../components/info/DisplayError";
import Loading from "../../../../components/info/Loading";
import StudentFileForm, { FieldsStudentFile } from "./StudentFileForm";

import { useUserState } from "../../../../hooks/useUserState";
import { formatCommonDefaultValuesStudentFile } from "../../../../utils/tuitionProcess";
import { useParameters } from "../../../../hooks/useParameters";

/**
 * Paso "Ficha Alumno" del Formulario de proceso de matrícula
 */
const StudentFile = () => {
  const prefix = "tuitionProcess.studentFile";
  const { t } = useTranslation();
  const { setIsSessionExpired } = useUserState();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [admissionType, setAdmissionType] =
    useState<{ id: string; name: string }>();
  const [backgroundData, setBackgroundData] = useState<BackgroundSectionType>();
  const [educationalBackground, setEducationalBackground] =
    useState<EducationalBackgroundType>();
  const [defaultValues, setDefaultValues] =
    useState<DefaultValues<FieldsStudentFile>>();
  const { communeOptions, regionOptions } =
    useParameters();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState("");

  const showAlert = useCallback(
    (textKey: string) => {
      setIsAlertOpen(true);
      setAlertText(t(`tuitionProcess.${textKey}`));
    },
    [t]
  );

  const getStudentFileInfo = useCallback(async () => {
    const { data, error } = await getStudentFile();
    if (data) {
      setDefaultValues(
        formatCommonDefaultValuesStudentFile(
          data,
          communeOptions,
          regionOptions
        )
      );
      setAdmissionType(data.admissionType);
      setBackgroundData({
        student: data.student,
        studyPlan: data.studyPlan,
        postulation: data.postulation,
      });
      setEducationalBackground(data.educationalBackground);
      setErrorLoading(undefined);

      showAlert(data.hasGratuity ? "hasGratuity" : "noGratuity");
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === "HTTP_ERROR" && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    setIsSessionExpired,
    showAlert,
    communeOptions,
    regionOptions,
  ]);

  useEffect(() => {
    if (loading) {
      getStudentFileInfo();
    }
  }, [loading, getStudentFileInfo]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    return <Loading insideCard />;
  }
  return (
    <>
      <Alert
        isOpen={isAlertOpen}
        toggle={() => setIsAlertOpen(false)}
        text={alertText}
        icon="information"
        fade
      />
      <Card className="px-4 px-md-5 py-4">
        <Row className="pb-4 pb-md-0">
          <Col xs={12} lg={7} md={8} xl={9}>
            <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
            <br />
            <span className="fs-18 text-primary fw-600">
              {backgroundData?.student.fullName}
            </span>
          </Col>
          <Col xs={12} lg={5} md={4} xl={3} className="pt-3 pt-md-0">
            <div className="box-outlined w-100 ml-md-auto w-100">
              <span className="text-secondary fw-700 text-uppercase">
                {t(`${prefix}.admissionType`)}
              </span>
              <br />
              {admissionType?.id && (
                <span className="fs-16 text-secondary">
                  {t(`admissionTypes.${admissionType.id}`)}
                </span>
              )}
            </div>
          </Col>
        </Row>
        <StudentFileForm
          backgroundData={backgroundData}
          educationalBackground={educationalBackground}
          defaultValues={defaultValues}
        />
      </Card>
    </>
  );
};

export default StudentFile;
