import {
  Button,
  OutlinedSelect,
  SelectOptionType,
  addToast,
  showDialogInfo,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'reactstrap';
import {
  getStudyPlanQuota,
  requestSaveCareerSelection,
  requestUpdateCareerSelection,
} from '../../../api/requests/tuitionProcess';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { useStepState } from '../../../hooks/useStepState';
import { useUserState } from '../../../hooks/useUserState';
import { useValidations } from '../../../hooks/useValidations';
import {
  StudyPlanQuotaResponse,
  TuitionOriginCodesEnum,
} from '../../../types/tuitionProcessResponseTypes';
import { useHistory } from 'react-router-dom';
import { POSTULATION_STATUS } from '../../../types/userTypes';

type InputsForm = {
  career: SelectOptionType | null;
};

enum STATUS_POSTULATION {
  PRE_STAGE = 'PRE_STAGE',
  IN_WAITING_LIST = 'IN_WAITING_LIST',
  ENROLLED = 'ENROLLED',
  STUDY_PLAN_CHANGE = 'STUDY_PLAN_CHANGE',
}

enum ModalType {
  None,
  WaitingList,
  QuotaExceeded,
  ChangeCareer,
}

const SelectCareer = () => {
  const { t } = useTranslation();
  const prefix = 'tuitionProcessNoSua.selectCareer';
  const history = useHistory();

  const {
    fullName,
    postulantId,
    setStatusPostulation,
    postulationDetail,
    statusPostulation,
    resetUserState,
  } = useUserState();
  const { handleSubmit, control, formState, getValues, watch, setValue } =
    useForm<InputsForm>();
  const { msgValidations } = useValidations();
  const [careers, setCareers] = useState<StudyPlanQuotaResponse[]>([]);
  const [optionsCareer, setOptionsCareer] = useState<SelectOptionType[]>([]);

  const [openedModal, setOpenedModal] = useState<ModalType>();

  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [confirming, setConfirming] = useState<boolean>(false);

  const [confirmChangeCareer, setConfirmChangeCareer] =
    useState<boolean>(false);

  const [selectedCareerId] = watch(['career']);
  const selectedCareer = useMemo(
    () => careers?.find((e) => `${e?.id}` === `${selectedCareerId?.value}`),
    [careers, selectedCareerId?.value],
  );

  const { nextStep } = useStepState();

  const getCareers = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getStudyPlanQuota();
    if (data) {
      setCareers(data);
      setOptionsCareer(
        data.map((career: any) => {
          return {
            value: career.id,
            label: `${career.studyPlanVersion.name} - Campus ${career.campus.name} - ${career.schedule.name}`,
          };
        }),
      );
      setErrorLoading(undefined);
    }
    if (error) {
      setErrorLoading('error');
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  useEffect(() => {
    getCareers();
  }, [getCareers]);

  const onSubmit = async (values: InputsForm) => {
    //Si no está matriculado
    if (statusPostulation !== STATUS_POSTULATION.ENROLLED) {
      if (!values.career) {
        return;
      }
      if (
        Number.isInteger(postulationDetail?.admissionId) &&
        postulationDetail?.admissionId !== values.career.value &&
        !confirmChangeCareer
      ) {
        setOpenedModal(ModalType.ChangeCareer);
      } else if (postulationDetail?.admissionId === values.career.value) {
        // Si quiere cambiar por la misma carrera, solo se pasa al siguiente paso
        nextStep();
      } else {
        await saveSelectionCareer(values.career.value, false);
      }
    }
  };

  const saveSelectionCareer = async (
    admissionId: number | string,
    acceptsWaitingList: boolean,
  ) => {
    const studentAdmissionId = parseInt(`${admissionId}`);
    const isUpdate =
      postulationDetail?.id && postulationDetail?.id !== studentAdmissionId;

    const dataRequest = {
      admissionId: studentAdmissionId,
      acceptsWaitingList,
      sub: postulantId,
    };

    const { data, error } = isUpdate
      ? await requestUpdateCareerSelection(dataRequest)
      : await requestSaveCareerSelection(dataRequest);

    if (data) {
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.saveSuccess`),
      });

      if (acceptsWaitingList) {
        setStatusPostulation(STATUS_POSTULATION.IN_WAITING_LIST);
      }
      if (isUpdate) {
        resetUserState();
      }
      setConfirmChangeCareer(false);
      setConfirming(false);
      const { details } = data;
      // Sólo debería existir un detalle por admissionId
      // en alguno de estos estados el cual correspondería
      // a la postulación actual
      const postDetail = details
        .filter((detail) => detail.admissionId === studentAdmissionId)
        .find(
          (detail) =>
            detail.status === POSTULATION_STATUS.IN_PROCESS ||
            detail.status === POSTULATION_STATUS.IN_WAITING_LIST ||
            detail.status === POSTULATION_STATUS.ENROLLED,
        );

      history.replace(`/tuition-process/steps/${postDetail?.id ?? undefined}`);
      nextStep();
    } else if (error) {
      if (
        error.data?.message?.[0] === 'QUOTA_EXCEEDED' &&
        !acceptsWaitingList
      ) {
        // Si excede la cuota y la confirmación es false
        // se abre modal para confirmar quedar en lista de espera
        setOpenedModal(ModalType.WaitingList);
      } else if (
        error.data?.message?.[0] === 'INVALID_REGISTRATION' &&
        !acceptsWaitingList
      ) {
        showDialogInfo({
          icon: {
            name: 'information',
            color: 'primary',
          },
          title: t(`${prefix}.modalNotAvailableTuition.title`),
          subtitle: t(`${prefix}.msgValidationChangeStudyPlan`),
          btnConfirm: {
            text: t(`${prefix}.modalNotAvailableTuition.btn`),
            color: 'primary',
            onConfirm: () => {},
          },
        });
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`${prefix}.saveError`),
        });
      }
    }
  };

  const acceptChangeCareer = async () => {
    setConfirmChangeCareer(true);
    setOpenedModal(ModalType.None);
    const admissionId = getValues('career')?.value;
    if (!admissionId) {
      setConfirmChangeCareer(false);
      return;
    }
    await saveSelectionCareer(
      typeof admissionId === 'string' ? parseInt(admissionId) : admissionId,
      false,
    );
  };

  const currentStudyPlanName = useMemo(() => {
    if (postulationDetail) {
      return `${postulationDetail.studyPlanName} - ${postulationDetail.campus.name} - ${postulationDetail.schedule.name}`;
    }
    return '';
  }, [postulationDetail]);

  /**
   * Se utiliza para inscribir al alumno en lista de espera luego de confirmar.
   */
  const acceptWaitingList = async () => {
    setConfirming(true);
    const admissionId = getValues('career')?.value;
    if (!admissionId) {
      setConfirming(false);
      return;
    }
    await saveSelectionCareer(
      typeof admissionId === 'string' ? parseInt(admissionId) : admissionId,
      true,
    );
    setOpenedModal(ModalType.None);
    setConfirming(false);
  };

  const showNotAvailableTuition = useCallback(
    (email: string, phone: string) => {
      setValue('career', null);
      showDialogInfo({
        icon: {
          name: 'information',
          color: 'primary',
        },
        title: t(`${prefix}.modalNotAvailableTuition.title`),
        subtitle: t(`${prefix}.modalNotAvailableTuition.subtitle`, {
          email,
          phone,
        }),
        btnConfirm: {
          text: t(`${prefix}.modalNotAvailableTuition.btn`),
          color: 'primary',
          onConfirm: () => {},
        },
      });
    },
    [setValue, t],
  );

  useEffect(() => {
    if (
      selectedCareer?.scopes?.length &&
      !selectedCareer?.scopes?.includes(TuitionOriginCodesEnum.Online)
    ) {
      showNotAvailableTuition(
        selectedCareer?.helpPhone,
        selectedCareer?.helpPhone,
      );
    }
  }, [
    selectedCareer?.helpEmail,
    selectedCareer?.helpPhone,
    selectedCareer?.scopes,
    showNotAvailableTuition,
  ]);

  if (loading && !errorLoading) {
    return <Loading insideCard />;
  }

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={t('common.errorLoadingInfo')}
        retryAction={getCareers}
        loadingAction={loading}
      />
    );
  }
  return (
    <Card className="px-4 px-md-5 py-4">
      <Row className="pb-4 pb-md-0">
        <Col xs={12} lg={7} md={8} xl={9}>
          <span className="fs-18 text-primary">{t(`${prefix}.student`)}</span>
          <br />
          <span className="fs-18 text-primary fw-600">{fullName}</span>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} className="py-3">
            <p>{t(`${prefix}.body`)}</p>
          </Col>
          <Col xs={12}>
            <div className="w-100 mx-auto" style={{ maxWidth: '600px' }}>
              <span className="fs-14 text-medium">{t(`${prefix}.career`)}</span>
              <OutlinedSelect
                name={'career'}
                options={optionsCareer}
                control={control}
                rules={{
                  required: msgValidations.required,
                }}
                disabled={formState.isSubmitting}
              />
            </div>
          </Col>
        </Row>

        <Row className="pb-5 pt-5">
          <Col xs={12} lg={4} className="ml-auto">
            <Button
              type="submit"
              text={t(`${prefix}.nextBtn`)}
              loading={formState.isSubmitting}
              disabled={!selectedCareer}
              fullwidth
            />
          </Col>
        </Row>
      </Form>

      <ConfirmationModal
        iconName="warning"
        isOpen={openedModal === ModalType.ChangeCareer}
        toggle={() => setOpenedModal(ModalType.None)}
        title={t(`${prefix}.modalChangeCareer.title`)}
        body={t(`${prefix}.modalChangeCareer.body`)}
        boxDetail={{
          title: t(`${prefix}.modalChangeCareer.detailTitle`),
          body: [currentStudyPlanName],
        }}
        loading={confirming}
        primaryBtn={{
          text: t(`${prefix}.modalChangeCareer.primaryBtn`),
          action: acceptChangeCareer,
        }}
        secondaryBtn={{
          text: t(`${prefix}.modalChangeCareer.secondaryBtn`),
          action: () => setOpenedModal(ModalType.None),
        }}
      />

      <ConfirmationModal
        isOpen={openedModal === ModalType.WaitingList}
        toggle={() => setOpenedModal(ModalType.None)}
        title={t(`${prefix}.modalWaitingList.title`)}
        body={t(`${prefix}.modalWaitingList.body`)}
        loading={confirming}
        primaryBtn={{
          text: t(`${prefix}.modalWaitingList.primaryBtn`),
          action: acceptWaitingList,
        }}
        secondaryBtn={{
          text: t(`${prefix}.modalWaitingList.secondaryBtn`),
          action: () => setOpenedModal(ModalType.None),
        }}
      />

      <ConfirmationModal
        isOpen={openedModal === ModalType.QuotaExceeded}
        toggle={() => setOpenedModal(ModalType.None)}
        title={t(`${prefix}.modalQuotaExceed.title`)}
        body={t(`${prefix}.modalQuotaExceed.body`)}
        primaryBtn={{
          text: t(`common.actions.understood`),
          action: () => setOpenedModal(ModalType.None),
        }}
      />
    </Card>
  );
};

export default SelectCareer;
