import { Select, TextInput } from "@octano/global-ui";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Row } from "reactstrap";
import { format as formatRut } from "rut.js";
import SectionTitle from "../../../../components/text/SectionTitle";
import { useValidations } from "../../../../hooks/useValidations";
import { BackgroundSectionType } from "../../../../types/studentFileSectionTypes";
import { formatDate } from "../../../../utils/dates";
import {
  maritalStatusOptions,
  sexCIOptions,
} from "../../../../utils/selectOptions";
import { useTuitionProcess } from "../../TuitionProcessContext";
import { FieldsStudentFile } from "./StudentFileForm";
import StudentPhoto, { StudentPhotoTexts } from "./StudentPhoto";

type BackgroundSectionProps = {
  data: BackgroundSectionType | undefined;
};

/**
 * Sección de Antecedentes
 */
const BackgroundSection = ({ data }: BackgroundSectionProps) => {
  const { control } = useFormContext<FieldsStudentFile>();
  const { prefix: translationPrefix } = useTuitionProcess();
  const prefix = `${translationPrefix}.studentFile.backgroundSection`;
  const { t } = useTranslation();
  const { msgValidations } = useValidations();

  const studentPhotoTexts = t<any, StudentPhotoTexts>(
    `${translationPrefix}.studentFile.studentPhoto`,
    { returnObjects: true }
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg="auto">
          <FormGroup>
            <div className="m-auto mw-100" style={{ width: "121px" }}>
              <StudentPhoto
                texts={studentPhotoTexts}
                defaultPhoto={data?.student.photo}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs={12} lg={true}>
          <Row>
            <Col className="pb-3" xs={12} lg={8}>
              <TextInput
                name="rut"
                label={t(`${prefix}.rut`)}
                value={data ? formatRut(data?.student.rut) : ""}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="studentNumber"
                label={t(`${prefix}.studentNumber`)}
                value={data?.postulation.number}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="namesStudent"
                label={t(`${prefix}.names`)}
                value={data?.student.names}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="paternalSurname"
                label={t(`${prefix}.paternalLastName`)}
                value={data?.student.paternalName}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name="maternalSurname"
                label={t(`${prefix}.maternalLastName`)}
                value={data?.student.maternalName}
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="cv"
            label={t(`${prefix}.cv`)}
            value={data?.studyPlan.code}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={8}>
          <TextInput
            name="studyPlan"
            label={t(`${prefix}.studyPlan`)}
            value={data?.studyPlan.name}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="campus"
            label={t(`${prefix}.campus`)}
            value={data?.studyPlan.campus}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="schedule"
            label={t(`${prefix}.schedule`)}
            value={data?.studyPlan.schedule}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="approvalDate"
            label={t(`${prefix}.approvalDate`)}
            value={
              data?.postulation.approvalDate
                ? formatDate(data?.postulation.approvalDate, "DD-MM-YYYY")
                : ""
            }
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="entryScore"
            label={t(`${prefix}.entryScore`)}
            value={data?.postulation.entryScore}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="ranking"
            label={t(`${prefix}.selectedPosition`)}
            value={data?.postulation.selectedPosition}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="admissionTerm"
            label={t(`${prefix}.admissionTerm`)}
            value={data?.postulation.admissionPeriod}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="entryYear"
            label={t(`${prefix}.entryYear`)}
            value={data?.postulation.entryYear}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="nationality"
            label={t(`${prefix}.nationality`)}
            value={data?.student?.nationality?.nationality}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="birthday"
            label={t(`${prefix}.birthday`)}
            value={
              data?.student.birthday
                ? formatDate(data?.student.birthday, "DD-MM-YYYY")
                : ""
            }
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="sexID"
            label={t(`${prefix}.sexID`)}
            options={sexCIOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="maritalStatus"
            label={t(`${prefix}.maritalStatus`)}
            options={maritalStatusOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
          />
        </Col>
      </Row>
    </>
  );
};

export default BackgroundSection;
