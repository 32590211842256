import { TUITION_PROCESS } from '../../../../api/endpoints';
import request, {
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../api/request';
import { AuthenticationError } from '../../../../api/requests/tuitionProcess';
import { SignDocumentationStepResponse } from '../../../../types/signDocumentationTypes';

export type GetSignDocumentationResultData = SignDocumentationStepResponse;

export type GetSignDocumentationResultError =
  | { code: 'QUOTA_EXCEEDED' }
  | AxiosResultDefaultError;

export type GetSignDocumentationResult = AxiosResult<
  GetSignDocumentationResultData,
  GetSignDocumentationResultError
>;

/**
 * Obtiene la información asociada al paso "Firmar Documentación"
 */
export async function getSignDocumentation(): Promise<GetSignDocumentationResult> {
  const result: GetSignDocumentationResult =
    await request<SignDocumentationStepResponse>(
      TUITION_PROCESS.SIGN_DOCUMENTATION,
    );
  if (result.error) {
    const resultError = result.error;
    if (
      resultError.code === 'HTTP_ERROR' &&
      resultError.data?.code === 'QUOTA_EXCEEDED'
    ) {
      return { error: { code: 'QUOTA_EXCEEDED' } };
    }
  }
  return result;
}

export type PostSignDocumentationResultData = unknown;

export type PostSignDocumentationResultError =
  | { code: 'QUOTA_EXCEEDED' }
  | AuthenticationError
  | AxiosResultDefaultError;

export type PostSignDocumentationResult = AxiosResult<
  PostSignDocumentationResultData,
  PostSignDocumentationResultError
>;

/*
 * Verificar la firma de los documentos y la carga de los CI
 */
export async function verifySignDocumentation(): Promise<PostSignDocumentationResult> {
  const result = await request(TUITION_PROCESS.SIGN_DOCUMENTATION_VERIFY, {
    method: 'post',
  });
  if (result.error) {
    const resultError = result.error;
    if (
      resultError.code === 'HTTP_ERROR' &&
      resultError.data?.code === 'QUOTA_EXCEEDED'
    ) {
      return { error: { code: 'QUOTA_EXCEEDED' } };
    }
  }
  return result;
}
