import { CheckInput, Select, TextInput } from "@octano/global-ui";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { clean } from "rut.js";
import { SectionTitle } from "../../../../components/text";
import { useParameters } from "../../../../hooks/useParameters";
import { useUserState } from "../../../../hooks/useUserState";
import { useValidations } from "../../../../hooks/useValidations";
import { getSelectedOption } from "../../../../utils/selectFormat";
import {
  maritalStatusOptions,
  relationshipOptions,
  RELATIONSHIP_OWN_SUSTAINER,
} from "../../../../utils/selectOptions";
import { FieldsCosignerForm } from "./CosignerForm";

type PersonalInformationProps = {
  disabledCheckSustainer: boolean;
  data?: FieldsCosignerForm;
};

/**
 * Sección datos personales del formulario perteneciente
 * al paso "Datos Aval" del proceso de matrícula
 */
const PersonalInformation = ({
  data,
  disabledCheckSustainer,
}: PersonalInformationProps) => {
  const { control, watch } = useFormContext<FieldsCosignerForm>();
  const prefix = "tuitionProcess.cosigner.personalInformation";
  const { t } = useTranslation();
  const { msgValidations, validateTextNotEmpty, validateRut } =
    useValidations();
  const checkSustainer = watch("checkSustainer");
  const hasPassport = watch("hasPassport");
  const { rut } = useUserState();

  const { countryOptions, nationalityOptions } = useParameters();

  const filteredRelationshipOptions = useMemo(
    () =>
      relationshipOptions.filter(
        (row) =>
          (checkSustainer && row.value === RELATIONSHIP_OWN_SUSTAINER) ||
          row.value !== RELATIONSHIP_OWN_SUSTAINER
      ),
    [checkSustainer]
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col xs={12} className="pb-4">
          <span className="fs-18 text-light fw-300">
            {t(`${prefix}.subtitle`)}
          </span>
        </Col>
      </Row>
      <Row>
        {/* Esta opción solo se despliega cuando el sostendor es distinto al alumno */}
        {!disabledCheckSustainer && (
          <Col className="pb-3" xs={12}>
            <CheckInput
              name="checkSustainer"
              label={t(`${prefix}.checkSustainer`)}
              control={control}
              shouldUnregister
            />
          </Col>
        )}
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="relationship"
            label={t(`${prefix}.relationship`)}
            options={filteredRelationshipOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={checkSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="occupation"
            label={t(`${prefix}.occupation`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="names"
            label={t(`${prefix}.names`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={checkSustainer}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="paternalLastName"
            label={t(`${prefix}.paternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={checkSustainer}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="maternalLastName"
            label={t(`${prefix}.maternalLastName`)}
            control={control}
            rules={{ validate: validateTextNotEmpty }}
            disabled={checkSustainer}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Row>
            {(checkSustainer && !data?.passportNumber) ||
            (!checkSustainer && !hasPassport) ? (
              <Col xs={12}>
                <TextInput
                  name="rut"
                  label={t(`${prefix}.rut`)}
                  control={control}
                  formatter="rut"
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      rut: validateRut,
                      studentAsCosigner: (value) => {
                        if (typeof value === 'string' && clean(value) === rut) {
                          return "No puedes ser tu propio aval";
                        } else {
                          return undefined;
                        }
                      },
                    },
                  }}
                  shouldUnregister={true}
                  disabled={checkSustainer === true}
                />
              </Col>
            ) : (
              <>
                <Col xs={12} md={6} className="pr-md-1">
                  <Select
                    name="passportCountry"
                    label={t(`${prefix}.passportCountry`)}
                    control={control}
                    options={countryOptions}
                    shouldUnregister={true}
                    isClearable={false}
                    disabled={checkSustainer === true}
                  />
                </Col>
                <Col xs={12} md={6} className="pl-md-1">
                  <TextInput
                    name="passportNumber"
                    label={t(`${prefix}.passportNumber`)}
                    control={control}
                    shouldUnregister={true}
                    disabled={checkSustainer === true}
                  />
                </Col>
              </>
            )}
            {!checkSustainer && (
              <Col xs={12} className="mb-n5">
                <CheckInput
                  name="hasPassport"
                  label={t(`${prefix}.checkPassport`)}
                  control={control}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="nationality"
            label={t(`${prefix}.nationality`)}
            value={getSelectedOption("", nationalityOptions)}
            control={control}
            options={nationalityOptions}
            isSearchable={true}
            rules={{ required: msgValidations.required }}
            disabled={checkSustainer}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name="maritalStatus"
            label={t(`${prefix}.maritalStatus`)}
            options={maritalStatusOptions}
            isSearchable={false}
            control={control}
            rules={{ required: msgValidations.required }}
            disabled={checkSustainer}
          />
        </Col>
      </Row>
    </>
  );
};

export default PersonalInformation;
