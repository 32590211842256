import { Steps } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { getStatusTuitionProcess } from '../../api/requests/tuitionProcess';
import DisplayError from '../../components/info/DisplayError';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useStepState } from '../../hooks/useStepState';
import { useTuitionProcessSteps } from '../../hooks/useTuitionProcessSteps';
import { useUserState } from '../../hooks/useUserState';
import StepProvider from '../../providers/StepProvider';
import withProvider from '../../providers/withProvider';

/**
 * Vista del proceso de matrícula
 */
const TuitionProcess = () => {
  const { setUserFullName, setUserRut, setIsSessionExpired } = useUserState();
  const { loading, setLoading, errorLoading, setErrorLoading } =
    useLoadingState();
  const [hasGratuity, setHasGratuity] = useState<boolean | null>(null);
  const { namesSteps, CurrentStepComponent, getIndexStep } =
    useTuitionProcessSteps(hasGratuity);
  const { currentStep, setCurrentStep } = useStepState();
  const [idStep, setIdStep] = useState<string>();

  const getStatus = useCallback(async () => {
    const { data, error } = await getStatusTuitionProcess();
    if (data) {
      setHasGratuity(data.hasPreassignedGratuity === true ? true : false);
      setUserFullName(data.studentFullName);
      setUserRut(data.studentRut);
      setIdStep(data.step);
      setErrorLoading(undefined);
    } else if (error) {
      setErrorLoading(error.code);
      if (error.code === 'HTTP_ERROR' && error.status === 401) {
        setIsSessionExpired(true);
      }
    }
    setLoading(false);
  }, [
    setErrorLoading,
    setLoading,
    setUserFullName,
    setUserRut,
    setIsSessionExpired,
  ]);

  useEffect(() => {
    if (loading) {
      getStatus();
    }
  }, [loading, getStatus]);

  useEffect(() => {
    if (idStep && hasGratuity !== null) {
      const index = getIndexStep(idStep);
      setCurrentStep(index);
    }
  }, [idStep, hasGratuity, getIndexStep, setCurrentStep]);

  if (errorLoading) {
    return (
      <DisplayError
        insideCard
        textBody={errorLoading}
        retryAction={() => setLoading(true)}
        loadingAction={loading}
      />
    );
  }
  if (loading) {
    // TODO: Loading component
    return <></>;
  }
  return (
    <Row>
      {/* Pasos del proceso */}
      <Col xs={12} className="pb-4">
        <Steps steps={namesSteps} currentStep={currentStep} />
      </Col>
      <Col xs={12} className="pt-2">
        {/* Se renderiza el componente adecuado para cada paso */}
        {CurrentStepComponent && <CurrentStepComponent />}
      </Col>
    </Row>
  );
};

export default withProvider(TuitionProcess, StepProvider);
