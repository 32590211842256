import { CERTIFICATES } from "../endpoints";
import request from "../request";

/**
 * Obtiene información de un certificado emitido
 */
export const getCertificateInfoRequest = (code: string) => {
  return request(CERTIFICATES.FIND_ONE + "/" + code, 
    { method: "GET" }
  );
};