import { addToast, Button, Modal } from "@octano/global-ui";
import { useCallback, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "reactstrap";
import { uploadIdentityCard } from "../../../../api/requests/tuitionProcess";
import FileInput from "../../../../components/form/FileInput";

export type InputsFormUploadIdentityCard = {
  front: any;
  back: any;
};

type IdentityCardModalProps = {
  type: string;
  isOpen: boolean;
  onClose: (uploaded?: boolean) => void;
};

const IdentityCardModal = ({
  type,
  isOpen,
  onClose,
}: IdentityCardModalProps) => {
  const prefix = "tuitionProcess.sign.identityCardModal";
  const { t } = useTranslation();
  const methods = useForm<InputsFormUploadIdentityCard>({
    mode: "onSubmit",
  });
  const reset = methods.reset;
  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  const onSubmit = useCallback(
    async (values: InputsFormUploadIdentityCard) => {
      const { error } = await uploadIdentityCard({
        type,
        front: values.front[0],
        back: values.back[0],
      });
      if (!error) {
        onClose(true);
      } else {
        addToast({
          icon: "error",
          color: "danger",
          text: t(`${prefix}.saveError`),
        });
      }
    },
    [type, onClose, t]
  );

  const requiredMsgError = t(`${prefix}.required`);
  return (
    <Modal isOpen={isOpen} toggle={onClose} closeOnBackdrop={false}>
      <Row className="text-center">
        <Col xs={12} className="pb-3">
          <span className="fs-22 text-dark">
            {t(`${prefix}.title.${type}`)}
          </span>
        </Col>
        <Col xs={12}>
          <p className="fs-16 fw-300">{t(`${prefix}.subtitle`)}</p>
        </Col>
      </Row>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row className="mb-5">
            <Col xs={12}>
              <FileInput
                name="front"
                label={t(`${prefix}.front`)}
                rules={{
                  required: requiredMsgError,
                }}
                onTrash={() => methods.setValue("front", undefined)}
                accept="image/png, image/jpeg, application/pdf"
              />
            </Col>
            <Col xs={12}>
              <FileInput
                name="back"
                label={t(`${prefix}.back`)}
                rules={{
                  required: requiredMsgError,
                }}
                onTrash={() => methods.setValue("back", undefined)}
                accept="image/png, image/jpeg, application/pdf"
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6} className="pb-2">
              <Button
                onClick={() => onClose()}
                text={t(`${prefix}.close`)}
                outlined
                fullwidth
                disabled={methods.formState.isSubmitting}
              />
            </Col>
            <Col xs={12} sm={6} className="pb-2">
              <Button
                type="submit"
                text={t(`${prefix}.save`)}
                fullwidth
                loading={methods.formState.isSubmitting}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default IdentityCardModal;
