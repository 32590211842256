import React, { useMemo } from 'react';
import { ColumnTable, Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { moneyFormatter } from '../../../../../utils/currency';
import { GatewayPaymentItem } from '../types';

interface IPaymentTableProps {
  data?: GatewayPaymentItem[];
  loading: boolean;
}

export default function PaymentTable({
  loading,
  data: items,
}: IPaymentTableProps) {
  const prefix = 'tuitionProcessNoSua.payment';
  const { t } = useTranslation();

  const columns: ColumnTable[] = useMemo(
    () => [
      {
        columnName: 'detail',
        headerText: t(`${prefix}.table.detail`),
        thClassName: 'text-left px-4',
        tdClassName: 'px-4 text-left',
        cellFormat({ row }) {
          return t(`${prefix}.table.${row.detail}`);
        },
      },
      {
        columnName: 'total',
        headerText: t(`${prefix}.table.total`),
        thClassName: 'text-right px-4',
        tdClassName: 'px-4 text-right font-weight-bold',
        cellFormat({ row }) {
          return moneyFormatter().format(row.total);
        },
      },
    ],
    [t],
  );

  return (
    <Row>
      <Col xs={12}>
        <div className="w-100 mx-auto">
          <div className="text-center">
            <Table
              isLoadingResults={loading}
              columns={columns}
              data={items ?? []}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
