import { useTranslation } from "react-i18next";
import validator from "validator";
import { clean, validate } from "rut.js";
import { isValidNumber } from 'libphonenumber-js';

export const useValidations = () => {
  const { t } = useTranslation();
  const prefixValidations = "common.validations";
  const msgValidations = {
    required: t(`${prefixValidations}.required`),
    invalidPhone: t(`${prefixValidations}.invalidPhone`),
    invalidEmail: t(`${prefixValidations}.invalidEmail`),
    invalidRut: t(`${prefixValidations}.invalidRut`),
  };

  /**
   * Valida que telefono tenga formato +XXXXXXXXXXX
   * @param phone
   * @returns
   */
  const validatePhone = (phone: any) => {
    if (phone && typeof phone === 'string' && phone !== '') {
      const cleanPhone = phone.replaceAll(' ', '');
      if (!isValidNumber(cleanPhone)) {
        return msgValidations.invalidPhone;
      }
    }
    if (phone && typeof phone !== 'string') {
      return msgValidations.invalidPhone;
    }
    return undefined;
  };

  /**
   * Valida que el texto ingresado tenga formato de correo
   * @param email
   * @returns
   */
  const validateEmail = (email: any) => {
    if (email && typeof email === 'string' && !validator.isEmail(email)) {
      return msgValidations.invalidEmail;
    }
    if (email && typeof email !== 'string') {
      return msgValidations.invalidEmail;
    }
    return undefined;
  };

  /**
   * Función que valida que el texto ingresado no sea vacío
   * y que no contenga sólo espacios.
   * @param value
   * @returns
   */
  const validateTextNotEmpty = (value: any) => {
    if (value === null || value === undefined) {
      return msgValidations.required;
    }

    if (typeof value === 'string' && value.trim().length === 0) {
      return msgValidations.required;
    }

    return undefined;
  };

  /**
   * Función que valida que el texto ingresado sea un rut válido
   * @param value
   * @returns
   */
  const validateRut = (value: any) => {
    if (value && typeof value === 'string' && !validate(clean(value))) {
      return msgValidations.invalidRut;
    }
    return undefined;
  };

  return {
    validatePhone,
    validateEmail,
    validateTextNotEmpty,
    validateRut,
    msgValidations,
  };
};
