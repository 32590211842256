import { Button, useMobile } from '@octano/global-ui';
import { DropEvent, useDropzone } from 'react-dropzone';
import { Col, Row } from 'reactstrap';

export interface StudentPhotoSelectProps {
  currentPhoto?: string | null;
  texts: StudentPhotoSelectTexts;
  onSelect: (selection: { file: File; src: string } | null) => void;
  onError: () => void;
}

const MIN_WIDTH = 300;
const MIN_HEIGHT = 300;

export interface StudentPhotoSelectTexts {
  title: string;
  body: string;
  upload: string;
  remove: string;
}

const classes = {
  container: 'image-square-container',
  imageSquare: 'image-square',
};

function fileValidator(file: File) {
  const fileErrors = [];

  const { width, height } = file as File & { width: number; height: number };
  if (width < MIN_WIDTH) {
    fileErrors.push({
      code: 'small-width',
      message: `Image width must be greater than ${MIN_WIDTH}`,
    });
  }
  if (height < MIN_HEIGHT) {
    fileErrors.push({
      code: 'small-height',
      message: `Image height must be greater than ${MIN_HEIGHT}`,
    });
  }
  return fileErrors.length ? fileErrors : null;
}

async function getFilesFromEvent(event: DropEvent) {
  const files = (event.target as any)?.files || [];
  const promises = [];
  for (const file of files) {
    const promise = new Promise<any>((resolve) => {
      const image = new Image();
      let url: string;
      image.onload = function () {
        file.width = image.width;
        file.height = image.height;
        resolve(file);
      };
      url = URL.createObjectURL(file);
      image.src = url;
    });
    promises.push(promise);
  }
  return Promise.all(promises);
}

const StudentPhotoSelect = (props: StudentPhotoSelectProps) => {
  const { texts, currentPhoto = null, onSelect, onError } = props;

  const isMobile = useMobile();

  const {
    getRootProps,
    getInputProps,
    open: openFileSelect,
  } = useDropzone({
    noClick: true,
    noDrag: true,
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDropAccepted: (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];
      onSelect({
        file: acceptedFile,
        src: URL.createObjectURL(acceptedFile),
      });
    },
    onDropRejected: (args) => {
      if (args.length) {
        if (args[0].errors) {
          onError();
        }
      }
    },
    getFilesFromEvent,
    validator: fileValidator,
  });

  return (
    <div
      {...getRootProps({
        className: 'dropzone position-relative',
      })}
    >
      <input {...getInputProps()} />
      <div className="d-flex flex-column align-items-center">
        <div className="mt-4 text-black fs-22 text-center">{texts.title}</div>
        <div className={`mx-auto mt-4 mb-4 fs-16 text-center`}>
          {texts.body}
        </div>
      </div>
      <div className="mb-5">
        <Row className="align-items-center justify-content-between">
          <Col xs="auto mb-2 mb-sm-0">
            <div
              style={{
                width: isMobile ? '100px' : '175px',
                maxWidth: '100%',
              }}
            >
              <div className={classes.container}>
                <div className={classes.imageSquare}>
                  <img
                    src={currentPhoto || '/images/no-photos.png'}
                    className="img img-responsive full-width"
                    alt="student"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs sm="auto">
            <Button
              icon="export"
              outlined
              fullwidth
              size="md"
              color="primary"
              text={texts.upload}
              onClick={() => {
                openFileSelect();
              }}
            />
            {Boolean(currentPhoto) && (
              <Button
                icon="trash"
                outlined
                fullwidth
                size="md"
                color="danger"
                text={texts.remove}
                onClick={() => {
                  onSelect(null);
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default StudentPhotoSelect;
