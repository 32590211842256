import { JWT_KEY } from "../config/constants";

/**
 * Función para guardar el token del usuario
 * @param token
 */
export const setJWT = (token: string) => localStorage.setItem(JWT_KEY, token);

/**
 * Función para obtener el token del usuario
 */
export const getJWT = () => localStorage.getItem(JWT_KEY);

/**
 * Función para remover el token del usuario
 */
export const removeJWT = () => localStorage.removeItem(JWT_KEY);
