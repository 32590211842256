import { TextInput } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import SectionTitle from "../../../../components/text/SectionTitle";
import { EducationalBackgroundType } from "../../../../types/studentFileSectionTypes";

type EducationalBackgroundSectionProps = {
  educationalBackground?: EducationalBackgroundType;
};
/**
 * Sección de antecedentes educacionales
 */
const EducationalBackgroundSection = ({
  educationalBackground,
}: EducationalBackgroundSectionProps) => {
  const prefix = "tuitionProcess.studentFile.educationalBackgroundSection";
  const { t } = useTranslation();
  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={8}>
          <TextInput
            name="highSchool"
            label={t(`${prefix}.highSchool`)}
            value={educationalBackground?.highSchool}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="countryHighSchool"
            label={t(`${prefix}.country`)}
            value={educationalBackground?.country?.name}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="regionHighSchool"
            label={t(`${prefix}.region`)}
            value={educationalBackground?.region?.name}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="communeHighSchool"
            label={t(`${prefix}.commune`)}
            value={educationalBackground?.commune?.name}
            disabled
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="graduationYear"
            label={t(`${prefix}.graduationYear`)}
            value={educationalBackground?.graduationYear}
            disabled
          />
        </Col>
      </Row>
    </>
  );
};

export default EducationalBackgroundSection;
