import { Button, Icon, Modal } from "@octano/global-ui";
import { Trans, useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { AccessType, LoginUnauthorizedType } from "../../../types/loginTypes";
import { formatDate } from "../../../utils/dates";

type LoginModalResponseProps = {
  isOpen: boolean;
  closeModal: () => void;
  loginState: LoginUnauthorizedType;
  accessType: AccessType;
};
const LoginModalResponse = ({
  isOpen,
  closeModal,
  loginState,
  accessType,
}: LoginModalResponseProps) => {
  const prefix = "loginTuitionProcess";
  const { t } = useTranslation();
  const { state, helpEmail, nextSubprocess, postulant } = loginState;
  const dateNextProcess = nextSubprocess
    ? `${formatDate(nextSubprocess.startedAt, "DD/MM/YYYY")} al ${formatDate(
        nextSubprocess.endedAt,
        "DD/MM/YYYY"
      )}`
    : "";
  const tAccessType = t(`${prefix}.accessType.${accessType}`);

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      {state === "NOT_IN_PROCESS" ? (
        <Row className="text-center">
          <Col xs={12} className="pb-2">
            <Icon name="error" size="52px" color="secondary" />
          </Col>
          <Col xs={12} className="pt-4 text-dark fw-400">
            <p className="fs-22">
              {t(`${prefix}.disabledToTitle`, {
                accessType: tAccessType,
              })}{" "}
            </p>
          </Col>
          <Col xs={12}>
            <p className="fs-18 fw-300">
              {t(`${prefix}.disabledToMsg`, {
                accessType: tAccessType,
              })}{" "}
              <span className="text-link">{helpEmail}</span>
            </p>
          </Col>
        </Row>
      ) : (
        <Row className="text-center">
          <Col xs={12} className="pb-2">
            <Icon name="waiting" size="52px" color="secondary" />
          </Col>
          <Col xs={12} className="pt-4 text-dark fw-400">
            <p className="fs-22 ">
              {t(`${prefix}.waitingListTitle`, {
                accessType: tAccessType,
              })}
            </p>
          </Col>
          <Col xs={12}>
            <p className="fs-18 fw-300 lh-30">
              <Trans t={t}>
                {t(`${prefix}.waitingListMsg`, {
                  date: dateNextProcess,
                  accessType: tAccessType,
                })}
              </Trans>
            </p>
            <p className="fs-18 fw-300 lh-30">
              {t(`${prefix}.showPostulationData`)}
            </p>
          </Col>
          <Col xs={12} className="justify-content pb-3">
            <div
              className="mx-auto text-left box-outlined"
              style={{ maxWidth: "350px" }}
            >
              <p className="text-secondary mb-0 fs-18">
                {t(`${prefix}.name`)}:{" "}
                <span className="fw-600">{postulant?.fullName}</span>
              </p>
              <p className="text-secondary mb-0 fs-18 text-break">
                {t(`${prefix}.email`)}:{" "}
                <span className="fw-600">{postulant?.email}</span>
              </p>
            </div>
          </Col>
          <Col xs={12}>
            <p className="fs-18 fw-300 lh-30">
              {t(`${prefix}.waitinListHelp`)}{" "}
              <span className="text-link">{helpEmail}</span>
            </p>
          </Col>
          <Col xs={12} className="px-5 pt-4">
            <Button
              text={t(`${prefix}.closeModalBtn`)}
              onClick={closeModal}
              fullwidth
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default LoginModalResponse;
