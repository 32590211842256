import { SelectOptionType } from '@octano/global-ui';

export const sexCIOptions: SelectOptionType[] = [
  { value: 1, label: 'Femenino' },
  { value: 2, label: 'Masculino' },
];

export const maritalStatusOptions: SelectOptionType[] = [
  { value: 1, label: 'Soltero/a' },
  { value: 2, label: 'Casado/a' },
  { value: 3, label: 'Viudo/a' },
  { value: 4, label: 'Divorciado/a' },
  { value: 5, label: 'Conviviente civil' },
];

export const educationalLevelOptions = [
  { value: 11, label: 'Básica Incompleta' },
  { value: 1, label: 'Básica Completa' },
  { value: 2, label: 'Media Incompleta' },
  { value: 3, label: 'Media Completa' },
  { value: 4, label: 'Superior Técnica' },
  { value: 5, label: 'Superior Profesional' },
  { value: 6, label: 'Licenciatura' },
  { value: 7, label: 'Profesional Universitaria' },
  { value: 8, label: 'Magister' },
  { value: 9, label: 'Doctorado' },
  { value: 10, label: 'No tengo información' },
];

export const familyGroupOptions = [
  { value: 1, label: 'Padres (uno o ambos)' },
  { value: 2, label: 'Padres (uno o ambos) y Hermanos' },
  { value: 3, label: 'Padres y abuelos (uno o más)' },
  { value: 4, label: 'Padres, abuelos y hermanos' },
  { value: 5, label: 'Conyuge (pareja)' },
  { value: 6, label: 'Conyuge (pareja) e hijos' },
  { value: 9, label: 'Sólo hijos' },
  { value: 7, label: 'Vivo solo' },
  { value: 8, label: 'Otros' },
];

export const parentalSurvivalOptions = [
  { value: 1, label: 'Padre y madre' },
  { value: 2, label: 'Solo Padre' },
  { value: 3, label: 'Solo Madre' },
  { value: 4, label: 'No tengo certeza' },
  { value: 5, label: 'Ninguno de los dos' },
];

export const ISAPRE = 1;
export const HEALTH_CARE_OTHER = 3;
export const HAS_INSURANCE = 1;

export const healthCareSystemOptions = [
  { value: 1, label: 'Isapre' },
  { value: 2, label: 'Fonasa' },
  { value: 3, label: 'Otro' },
];

export const healthCareProviderOptions = [
  { value: 1, label: 'Isapre Banmédica' },
  { value: 2, label: 'Isapre Consalud' },
  { value: 3, label: 'Isapre Colmena' },
  { value: 4, label: 'Isapre CruzBlanca' },
  { value: 5, label: 'Isapre Nueva Masvida' },
  { value: 6, label: 'Isapre Vida Tres' },
];

export const healthInsuranceOptions = [
  { value: 1, label: 'Si' },
  { value: 0, label: 'No' },
];

export const relationshipOptions = [
  { value: 1, label: 'Padre' },
  { value: 2, label: 'Madre' },
  { value: 3, label: 'Abuelo(a)' },
  { value: 4, label: 'Cónyuge' },
  { value: 5, label: 'Otro familiar' },
  { value: 6, label: 'Sin relación familiar' },
  { value: 7, label: 'Misma persona' },
];
export const RELATIONSHIP_NO_FAMILY = 6;
export const RELATIONSHIP_OWN_SUSTAINER = 7;

export const sustainerTypeOptions = [
  { value: 'NP', label: 'Persona natural' },
  { value: 'LP', label: 'Persona jurídica' },
];

export enum SustainerType {
  NaturalPerson = 'NP',
  LegalPerson = 'LP',
}
